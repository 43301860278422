import React from "react";
import {
  programPassportData,
  programResidenceData,
} from "../../data/programData";
import { NavLink } from "react-router-dom";
import { IconRight } from "../../assets/svgExport";
import {
  backgroundProgram,
  backgroundWealth,
  backgroundLegacy,
  schoolsBackground,
} from "../../assets/imgExport";
import { HashLink } from "react-router-hash-link";

function HeroSection({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: string;
}) {
  return (
    <>
      <div className="relative h-96 w-full overflow-hidden flex items-center justify-start px-20">
        <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/20 z-[1]" />
        <img
          className="absolute bottom-[-35%] right-0 w-screen object-cover"
          src={image}
          alt={title + " background"}
        />
        <div className="relative z-10 text-white">
          <h1 className="text-inherit flex items-center">{title}</h1>
          <div className="h-[0.125rem] w-40 bg-white/70 my-5"></div>
          <p className="w-[50%] opacity-90 leading-relaxed">{description}</p>
        </div>
      </div>
    </>
  );
}

export function ServiceProgramPage() {
  function ProgramBlock({
    item,
    index,
    type,
  }: {
    item: any;
    index: number;
    type: string;
  }) {
    const amount = item.investmentAmount;
    return (
      <NavLink to={"/program/" + type + "/" + item.countryEn}>
        <div key={"program-imigration-" + index} className="">
          <img
            className="h-6 shadow-md border border-white rounded"
            src={item.image.flag}
            alt={item.country + item.immigrationType.title}
          />
          <div className="mt-4 mb-6 flex items-center gap-2">
            <p className="font-bold text-xl text-secondary-dark">
              {item.country + " " + item.immigrationType.title}
            </p>
            <p className="bg-gray-200 px-3 text-sm text-main/50 py-[0.125rem] rounded-md">
              {item.continent}
            </p>
          </div>
          <ul className=" text-gray-600 space-y-2">
            <li>
              {item.currency.symbol}
              {amount.set.toLocaleString()}
              {amount.optional ? "/" + amount.optional.toLocaleString() : ""}
              {amount.above ? "起" : ""}
            </li>
            <li>免签{item.countryOverview.visaFree}个国家</li>
            <li>官方语言：{item.countryOverview.officialLanguage}</li>
            <li>
              申请周期：{item.process.duration.min}-{item.process.duration.max}
              个{item.process.duration.unit}
            </li>
          </ul>

          <p className="flex items-center gap-2 text-sm text-main/50 hover:text-main mt-8 transition-colors">
            <span>了解更多</span>
            <IconRight width={16} height={16} fill={"#aaa"} />
          </p>
        </div>
      </NavLink>
    );
  }

  function ProgramsSection() {
    return (
      <div className="pt-20 bg-gray-50">
        <div id="residence" className="px-20 pb-20 w-full">
          <h2 className="mb-16 text-3xl font-bold text-gray-800">居留项目</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-24">
            {Object.values(programResidenceData).map((item, index) => {
              return (
                <ProgramBlock
                  item={item}
                  index={index}
                  type="residence"
                  key={"program-residence-" + index}
                />
              );
            })}
          </div>
        </div>
        <div id="passport" className="mt-20 p-20 py-40 bg-tertiary-light/20">
          <h2 className="mb-16 text-3xl font-bold text-gray-800">护照项目</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-24">
            {Object.values(programPassportData).map((item, index) => {
              return (
                <ProgramBlock
                  item={item}
                  index={index}
                  type="passport"
                  key={"program-passport-" + index}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center bg-gray-50 font-poster2">
      <HeroSection
        title="身份规划"
        description="我们协助客户灵活获取欧盟、英联邦及其他热门国家的居留与护照，实现全球通行与跨境生活，享受更多投资、教育与医疗资源。"
        image={backgroundProgram}
      />
      <ProgramsSection />
    </div>
  );
}

export function ServiceWealthPage() {
  function TaxSection() {
    return (
      <div id="tax" className="p-20 py-40 w-full">
        <h2 className="text-3xl font-bold text-gray-800">税务规划</h2>
        <p className="text-lg text-gray-400 mb-16 mt-2">
          跨辖区税收规划、资产保护与继承
        </p>
        <div className="flex flex-col gap-12">
          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700">
              服务内容概述
            </h3>
            <p className="text-gray-600 mb-6">
              无论是成立新的家族办公室，还是审查及优化现有结构，我们都能根据不同国家和地区的法律法规，为高净值家庭设计最优的跨辖区税收规划、资产保护和财富继承方案。{" "}
            </p>
            <ul className=" space-y-3 text-gray-600 mb-8">
              <li>
                <b>跨辖区税务合规及规划</b>
                ：根据各国税制的异同，为客户量身制定最优的税收架构，最大限度地减少不必要的税务负担。
              </li>
              <li>
                <b>资产保护与风险管理</b>
                ：通过信托、基金会或其他法律工具有效隔离风险，保护家族财产。
              </li>
              <li>
                <b>继承与家族治理规划</b>
                ：综合考量家族成员的长期利益，制定合理的股权结构及继承安排，为家族企业与财富传承奠定稳固基础。
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              常见问题与解决方案
            </h3>

            <ul className=" space-y-6 text-gray-600 mb-8 leading-relaxed">
              <li>
                <p className="text-gray-600 ">
                  <b>过时结构的重新设计</b>
                  ：许多家族办公室的架构由于政策变化或家族规模的扩大已经不再适用。
                </p>
                <p>提供重组建议及实施支持：确保家族办公室架构更精简高效。</p>
              </li>
              <li>
                <p className="text-gray-600 ">
                  <b>家庭成员跨国迁移</b>
                  ：对准备移居英国或欧洲其他国家的家庭成员，入境前税务筹划至关重要。
                </p>
                <p>
                  与当地权威机构和律师事务所紧密合作，制定合理的入境前后税务计划。
                </p>
                <p>
                  提前布局居民身份规划及全球收入申报，避免双重征税或潜在税务纠纷。
                </p>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              我们的增值优势
            </h3>
            <ul className=" space-y-3 text-gray-600">
              <li>
                <b>与多国政府、金融机构及税务部门紧密联系</b>
                ：能够第一时间掌握政策变化，为家庭提供及时调整方案。
              </li>
              <li>
                <b>协作团队</b>
                ：与各领域（法律、税务、移民、保险等）的专家常年合作，多视角确保方案可行且合规。
              </li>
              <li>
                <b>全程陪伴式服务</b>
                ：从结构设计到后续维护，提供一站式支持，减轻客户在日常事务管理中的负担。
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  function CompanySection() {
    return (
      <div id="company" className="p-20 py-40 pb-60 bg-tertiary-light/20">
        <h2 className="text-3xl font-bold text-gray-800">公司服务</h2>
        <p className="text-lg text-gray-600 mb-16 mt-2">一站式注册与运营支持</p>

        <div className="flex flex-col gap-12">
          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              服务国家与项目概述
            </h3>
            <p className="text-gray-600 mb-6">
              我们为客户在英国、瑞士、爱尔兰、德国、马耳他、希腊及塞浦路斯等欧洲国家建立公司，并提供后续一系列配套服务。
            </p>
            <ul className=" space-y-3 text-gray-600 mb-8">
              <li>
                <b>公司注册与设立</b>
                ：从选定注册地、公司类型到办理营业执照、税务登记等提供全流程支持。
              </li>
              <li>
                <b>银行开户与财务管理</b>
                ：协助客户在目标国家开设公司账户，进行日常财务管理及审计。
              </li>
              <li>
                <b>公司治理与合规</b>
                ：根据当地法规要求，提供公司秘书服务、年报审计、合规性维护，降低企业经营风险。
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              核心服务清单
            </h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-main text-white">
                    <th className="p-4 text-left">服务项目</th>
                    <th className="p-4 text-left">具体内容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-gray-200 bg-gray-50/50">
                    <td className="p-4 font-bold">公司秘书服务</td>
                    <td className="p-4">
                      提供地址挂靠、董事会记录管理、年报申报等必要事项
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50">
                    <td className="p-4 font-bold">公司股份转换</td>
                    <td className="p-4">
                      协助完成公司股权转让、资本变更及增资减资的法律文件及手续
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50/50">
                    <td className="p-4 font-bold">公司年末审计</td>
                    <td className="p-4">
                      协助筛选并对接当地会计及审计机构，完成年末报表及审计报告
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50">
                    <td className="p-4 font-bold">公司更名</td>
                    <td className="p-4">
                      根据客户需求及当地法律规定，快速更改公司名称并完成相应备案
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50/50">
                    <td className="p-4 font-bold">公司季度或年度报表</td>
                    <td className="p-4">定期财务数据整理、申报及合规性评估</td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50">
                    <td className="p-4 font-bold">公司董事任命及撤销</td>
                    <td className="p-4">
                      提供法律文本与流程指引，确保董事信息变更合规有效
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50/50">
                    <td className="p-4 font-bold">公司地址和电话服务</td>
                    <td className="p-4">
                      提供虚拟办公室服务、专属电话线路等以满足灵活办公需要
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50">
                    <td className="p-4 font-bold">公司注销服务</td>
                    <td className="p-4">
                      完成注销流程中的必要文件及政府部门办理程序
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50/50">
                    <td className="p-4 font-bold">公司银行开户</td>
                    <td className="p-4">
                      推荐合适银行并协助准备开户文件，简化开户流程
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50">
                    <td className="p-4 font-bold">公司增值税注册</td>
                    <td className="p-4">
                      代表客户完成增值税(VAT)相关登记及报表提交
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 bg-gray-50/50">
                    <td className="p-4 font-bold">公司股东注册</td>
                    <td className="p-4">
                      代为更新并维护股东名册，确保信息及时且准确
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 mt-8 ">
              增值要点
            </h3>
            <ul className=" space-y-3 text-gray-600">
              <li>
                <b>多国团队配合</b>
                ：在各个国家均有专业代理人和律师团队，随时解决当地法律合规问题。
              </li>
              <li>
                <b>节省时间成本</b>
                ：统一窗口对接，避免客户在各国间往返协调，提升效率。
              </li>
              <li>
                <b>长期咨询服务</b>
                ：公司设立后，还可持续提供财务、税务、法律等多方位支持，保障公司稳定发展。
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white">
      <HeroSection
        title="财富规划"
        description="我们协助客户规划财富，通过合法合规的税务筹划、资产配置、家族信托等手段，实现财富的保值增值，同时确保家族财富的传承与保护。"
        image={backgroundWealth}
      />
      <TaxSection />
      <CompanySection />
    </div>
  );
}

export function ServiceLegacyPage() {
  function TrustSection() {
    return (
      <div>
        <div id="trust" className="p-20 py-40 w-full">
          <h2 className=" text-4xl font-bold text-gray-800">家族信托</h2>
          <p className="text-lg text-gray-400 mt-2 mb-16">财富传承与资产保护</p>
          <div className="flex flex-col gap-12">
            <div>
              <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
                重要性与核心价值
              </h3>
              <p className="text-gray-600 mb-6">
                家族信托在海外已经发展了数百年，至今仍然是超高净值人士进行全球化资产保护与传承规划的核心工具。
              </p>
              <ul className=" space-y-3 text-gray-600 mb-8">
                <li>
                  <b>资产隔离与保护</b>
                  ：将资产放入信托后，可有效防止外部债务或诉讼风险，对家庭成员的财产进行分层保护。
                </li>
                <li>
                  <b>跨代传承</b>
                  ：信托可根据委托人的意愿制订复杂的分配机制和条件，实现家族企业及资产的长远传承。
                </li>
                <li>
                  <b>隐私与保密</b>
                  ：信托文件通常不在公共登记系统中公示，大幅降低了信息泄露的风险。
                </li>
              </ul>
            </div>
            <div>
              <div>
                <h3 className="mb-8 text-2xl font-semibold text-gray-700 mb-8">
                  信托架构设计要点
                </h3>
                <ul className="space-y-6 text-gray-600 mb-8">
                  <li>
                    <p className="mb-2">
                      <b>
                        属地选择：结合家族整体税收规划与身份规划，选择在英国、瑞士、新加坡或香港等地建立信托。
                      </b>
                    </p>
                    <p className="">当地税法与国际税收协定的影响</p>
                    <p className="">税务居民身份变化后的管理及继承安排</p>
                  </li>
                  <li>
                    <p className="mb-2">
                      <b>
                        委托人及受益人需求：深入了解家庭成员结构、企业性质及传承目标后定制信托协议。
                      </b>
                    </p>
                    <p className="">为家族年轻一代提供长期教育及生活资金</p>
                    <p className="">设立慈善条款或特别公益项目</p>
                  </li>
                  <li>
                    <b>专家协同</b>
                    ：与英国、瑞士、新加坡、香港等多地顶尖律所与税务专家合作，确保信托的合规性及效用最大化。
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
                设立海外家族信托的典型好处
              </h3>
              <ul className=" space-y-3 text-gray-600">
                <li>
                  <b>身份与税务规划相结合</b>
                  ：在全球范围内运用不同国家的税收优惠政策，最大化保留家族资产。
                </li>
                <li>
                  <b>减少遗产税和所得税</b>
                  ：通过合法架构设计，将潜在税负降至最低。
                </li>
                <li>
                  <b>提高家庭稳定与和谐</b>
                  ：避免子女挥霍或因继承产生纠纷，促进家族代际间的稳固关系。
                </li>
                <li>
                  <b>资产的保值与增值</b>
                  ：结合全球投资机会进行多元化配置，降低单一市场波动影响。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function EducationSection() {
    return (
      <div id="education" className="p-20 py-40 bg-tertiary-light/20">
        <h2 className="text-3xl font-bold text-gray-800">教育规划</h2>
        <p className="text-lg text-gray-400 mt-2 mb-16">
          英国私校一站式留学服务
        </p>
        <div className="flex flex-col gap-12">
          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              服务简介
            </h3>
            <p className="text-gray-600 mb-6">
              新愿景教育成立于2014年，是一家提供英国低龄私校留学的本土教育机构，专门面向高净值家庭提供定制化的留学规划。
            </p>
            <ul className=" space-y-3 text-gray-600 mb-8">
              <li>
                <b>学校选择与申请</b>
                ：深入了解学生的学习背景、兴趣与特长，匹配最符合需求的精英私校。
              </li>
              <li>
                <b>入学考试培训与监护</b>：协助准备Common
                Entrance、UKiset等考试，并提供监护及留学过程监督。
              </li>
              <li>
                <b>长期规划与跟踪服务</b>
                ：从中学到大学，为学生提供多年的教育计划管理与学业指导，优化未来升学路径。
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              名校资源与优势
            </h3>
            <ul className=" space-y-3 text-gray-600 mb-8">
              <li>
                <b>与英国精英私校长期合作</b>
                ：对各校教学特色、录取标准与优势科目了然于心，可精准匹配。
              </li>
              <li>
                <b>提升竞争力</b>
                ：提早规划学术和课外活动，为学生申请牛津、剑桥、G5及罗素大学集团名校打下坚实基础。
              </li>
              <li>
                <b>全方位家庭支持</b>
                ：不仅关注学生成绩，也为家长提供移民、置业、金融等配套咨询，做好综合规划。
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              增值要点
            </h3>
            <ul className=" space-y-3 text-gray-600 mb-8">
              <li>
                <b>个性化辅导</b>
                ：一对一学术提升方案，结合学生的学习风格与兴趣，确保在激烈的竞争中脱颖而出。
              </li>
              <li>
                <b>文化与社交融入</b>
                ：通过暑期项目、课外实践活动，帮助学生更好地适应英国的学习和生活环境。
              </li>
              <li>
                <b>持续追踪服务</b>
                ：毕业后仍可提供职业发展、实习推荐等资源，实现教育与事业的无缝对接。
              </li>
            </ul>
          </div>
        </div>
        <img
          src={schoolsBackground}
          alt="schools"
          className="w-full rounded-lg shadow-lg mt-16"
        />
      </div>
    );
  }

  function CharitySection() {
    return (
      <div id="charity" className="p-20 py-40 pb-60">
        <h2 className=" text-3xl font-bold text-gray-800">慈善公益</h2>
        <p className="text-lg text-gray-400 mt-2 mb-16">家族办公室的关键组成</p>

        <div className="flex flex-col gap-12">
          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              价值与意义
            </h3>
            <p className="text-gray-600 mb-6">
              对于超高净值家庭而言，慈善公益不仅是回馈社会的方式，更是提升家族凝聚力和培养年轻一代责任感的重要途径。
            </p>
            <ul className=" space-y-3 text-gray-600 mb-8">
              <li>
                <b>社会责任与影响力</b>
                ：通过有组织的慈善活动，家族可以在所关注的领域发挥更大影响力。
              </li>
              <li>
                <b>税收与经济效益</b>
                ：合理利用慈善信托或基金会结构，可在满足家族慈善需求的同时，达成税务优化。
              </li>
              <li>
                <b>培养年轻一代</b>
                ：让家庭年轻成员直接参与慈善项目的决策和执行，学习管理及沟通技巧，并获得成就感。
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              我们的慈善规划与组织服务
            </h3>
            <ul className=" space-y-3 text-gray-600 mb-8">
              <li>
                <b>慈善意图与目标分析</b>
                ：深入了解家庭的价值观与捐赠意愿，量身定制慈善模式。
              </li>
              <li>
                <b>一次性捐赠、长期资助或设立慈善基金会</b>
              </li>
              <li>
                <b>聚焦教育、医疗、环保等特定公益领域</b>
              </li>
              <li>
                <b>慈善项目筛选与落地</b>
                ：在全球范围内寻找合适的公益机构或项目，确保资金使用透明并持续产生正面影响。
              </li>
              <li>
                <b>税务与合规管理</b>
                ：设计最优结构，如在家族信托或公司旗下设立慈善分支，以实现长期慈善目标与税收减免。
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-8 text-2xl font-semibold text-gray-700 ">
              结合投资策略的慈善规划
            </h3>
            <ul className=" space-y-3 text-gray-600">
              <li>
                <b>影响力投资（Impact Investing）</b>
                ：将家族在公益领域的理念融入到投资策略中，兼顾财务回报与社会效益。
              </li>
              <li>
                <b>可持续发展策略</b>
                ：在家族资产配置中注重ESG（环境、社会与公司治理）标准，与慈善事业相辅相成。
              </li>
              <li>
                <b>家族价值观传承</b>
                ：通过年度慈善活动、家庭慈善会议等形式，让家族成员共同参与决策，增强家族内部的凝聚力与文化传承。
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <HeroSection
        title="家族传承"
        description="我们协助客户规划家族传承，通过家族宪章、家族办公室、家族信托等手段，实现家族财富的传承与保护，同时确保家族文化的传承与发扬。"
        image={backgroundLegacy}
      />
      <TrustSection />
      <EducationSection />
      <CharitySection />
    </div>
  );
}
