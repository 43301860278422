// src/components/HtmlOverflowManager.tsx
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { setLang } from "../../features/app/appSlice";

const UserSettingManager: React.FC = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const languageParam = urlParams.get("language");

    if (languageParam) {
      const isEnglish = languageParam === "english";
      dispatch(setLang(isEnglish));
      localStorage.setItem("language", isEnglish ? "english" : "other");
    } else {
      const storedLanguage = localStorage.getItem("language");
      if (storedLanguage) {
        dispatch(setLang(storedLanguage === "english"));
      } else {
        dispatch(setLang(true));
        localStorage.setItem("language", "english");
      }
    }
  }, []);

  return null; // This component doesn't render anything
});

export default UserSettingManager;
