// src/components/HtmlOverflowManager.tsx
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getIsRealMobile,
  getShowContactPopup,
  getShowFilter,
} from "../../features/app/appSlice";

const HtmlOverflowManager: React.FC = React.memo(() => {
  const showContactPopup = useSelector(getShowContactPopup);
  const isRealMobile = useSelector(getIsRealMobile);
  const showFilter = useSelector(getShowFilter);

  useEffect(() => {
    const html = document.querySelector("html");
    if (showContactPopup) {
      if (html) html.style.overflow = "hidden";
    } else {
      if (html) html.style.overflow = "unset";
    }
  }, [showContactPopup]);

  useEffect(() => {
    if (!isRealMobile) return;
    if (window.location.pathname !== "/property") return;
    const html = document.querySelector("html");
    if (showFilter) {
      if (html) html.style.overflow = "hidden";
    } else {
      if (html) html.style.overflow = "unset";
    }
  }, [showFilter, isRealMobile]);

  return null; // This component doesn't render anything
});

export default HtmlOverflowManager;
