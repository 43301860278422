import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Product {
  id: number;
  name: string;
  price: number;
  description: string;
  category: string;
}

export interface ProductsState {
  items: Record<number, Product>;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  filters: {
    category: string | null;
    minPrice: number | null;
    maxPrice: number | null;
    searchTerm: string | null;
  };
}

const initialState: ProductsState = {
  items: {},
  status: "idle",
  error: null,
  filters: {
    category: null,
    minPrice: null,
    maxPrice: null,
    searchTerm: null,
  },
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const response = await fetch("/api/products");
    const products = await response.json();
    return products.reduce((acc: Record<number, Product>, product: Product) => {
      acc[product.id] = product;
      return acc;
    }, {});
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload;
      state.filters[key as keyof typeof state.filters] = value;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      });
  },
});

// Selectors
export const selectAllProducts = (state: RootState) => state.products.items;
export const selectProductsStatus = (state: RootState) => state.products.status;
export const selectProductsError = (state: RootState) => state.products.error;
export const selectFilters = (state: RootState) => state.products.filters;

export const selectFilteredProducts = createSelector(
  [selectAllProducts, selectFilters],
  (products: Record<string, Product>, filters) => {
    return Object.values(products).filter((product) => {
      const matchesCategory =
        !filters.category || product.category === filters.category;
      const matchesMinPrice =
        !filters.minPrice || product.price >= filters.minPrice;
      const matchesMaxPrice =
        !filters.maxPrice || product.price <= filters.maxPrice;
      const matchesSearch =
        !filters.searchTerm ||
        product.name.toLowerCase().includes(filters.searchTerm.toLowerCase());

      return (
        matchesCategory && matchesMinPrice && matchesMaxPrice && matchesSearch
      );
    });
  }
);

export const { setFilter, clearFilters } = productsSlice.actions;
export default productsSlice.reducer;
