// REACT, REDUX, HELMET //
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// PAGES & LAYOUTS //
import Header from "../layouts/Header/Header";
import Footer from "../layouts/Footer/Footer";
import HomePage from "../pages/Home/HomePage";
import {
  PoliciesCookiesPage,
  PoliciesPrivacyPage,
  PoliciesTermsPage,
} from "../pages/Policies/PoliciesPage";
import NotFoundPage from "../pages/Exceptions/NotFoundPage";
import Cookie from "../layouts/Cookie/Cookie";

// STATES FROM SLICE //
import { getLayoutHeaderHeight } from "../features/app/layoutsSlice";
import { programPassportData, programResidenceData } from "../data/programData";
import ProgramPage from "../pages/Program/ProgramPage";
import {
  ServiceLegacyPage,
  ServiceProgramPage,
  ServiceWealthPage,
} from "../pages/Service/ServicePages";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const AppRoutes = () => {
  // TODO: get user which decides if account page is visible
  const headerHeight = useSelector(getLayoutHeaderHeight);

  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <main
        className={`transition-all duration-500 ease-in-out z-[999] bg-white`}
        // className={`${headerHeight && "mt-[" + headerHeight + "px]"} relative transition-all duration-500 ease-in-out z-[999]`}
        style={{
          minHeight: `calc(100vh - ${headerHeight}px)`,
          // top: headerHeight + "px",
          paddingTop: headerHeight + "px",
          WebkitMinHeight: `calc(100vh - ${headerHeight}px)`,
        }}
      >
        <Routes>
          {Object.entries(programResidenceData).map(([key, value]) => (
            <Route
              key={key}
              path={`/program/residence/${key}`}
              element={
                <>
                  <Helmet>
                    <title>{key.toUpperCase()} Residence | NVFO</title>
                    <meta name="description" content={value.description} />
                  </Helmet>
                  <ProgramPage programData={value} />
                </>
              }
            />
          ))}
          {Object.entries(programPassportData).map(([key, value]) => (
            <Route
              key={key}
              path={`/program/passport/${key}`}
              element={
                <>
                  <Helmet>
                    <title>{key.toUpperCase()} Passport | NVFO</title>
                    <meta name="description" content={value.description} />
                  </Helmet>
                  <ProgramPage programData={value} />
                </>
              }
            />
          ))}

          <Route
            path="/program"
            element={
              <>
                <Helmet>
                  <title>身份规划 | NVFO</title>
                </Helmet>
                <ServiceProgramPage />
              </>
            }
          />
          <Route
            path="/wealth"
            element={
              <>
                <Helmet>
                  <title>财富规划 | NVFO</title>
                </Helmet>
                <ServiceWealthPage />
              </>
            }
          />
          <Route
            path="/legacy"
            element={
              <>
                <Helmet>
                  <title>传承规划 | NVFO</title>
                </Helmet>
                <ServiceLegacyPage />
              </>
            }
          />
          <Route path="/policies/cookies" element={<PoliciesCookiesPage />} />
          <Route path="/policies/privacy" element={<PoliciesPrivacyPage />} />
          <Route path="/policies/terms" element={<PoliciesTermsPage />} />
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>New Vision Family Office</title>
                  <meta
                    name="description"
                    content="Welcome to New Vision Family Office."
                  />
                  <meta name="description" content="" />
                  <meta name="keywords" content="" />
                </Helmet>
                <HomePage />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <Helmet>
                  <title>404 | NVFO</title>
                  <meta name="description" content="Page not found." />
                </Helmet>
                <NotFoundPage />
              </>
            }
          />
        </Routes>
      </main>
      <Footer />
      {/* <Cookie /> */}
    </BrowserRouter>
  );
};
