import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface appState {
  isWidthHideFilter: boolean;
  showFilter: boolean;
  showContactPopup: boolean;
  isMobile: boolean;
  isRealMobile: boolean;
  isSmallScreen: boolean;
  lang: boolean;
}

const initialState: appState = {
  isWidthHideFilter: false,
  showFilter: false,
  showContactPopup: false,
  isMobile: true,
  isRealMobile: true,
  isSmallScreen: true,
  lang: true,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<boolean>) => {
      state.lang = action.payload;
    },
    setIsWidthHideFilter: (state, action: PayloadAction<boolean>) => {
      state.isWidthHideFilter = action.payload;
    },
    setShowFilter: (state, action: PayloadAction<boolean>) => {
      state.showFilter = action.payload;
    },
    setShowContactPopup: (state, action: PayloadAction<boolean>) => {
      state.showContactPopup = action.payload;
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setIsRealMobile: (state, action: PayloadAction<boolean>) => {
      state.isRealMobile = action.payload;
    },
    setIsSmallScreen: (state, action: PayloadAction<boolean>) => {
      state.isSmallScreen = action.payload;
    },
  },
});

// SELECTORS
export const getIsWidthHideFilter = (state: any) => state.app.isWidthHideFilter;
export const getShowFilter = (state: any) => state.app.showFilter;
export const getShowContactPopup = (state: any) => state.app.showContactPopup;
export const getIsMobile = (state: any) => state.app.isMobile;
export const getIsRealMobile = (state: any) => state.app.isRealMobile;
export const getIsSmallScreen = (state: any) => state.app.isSmallScreen;
export const getLang = (state: any) => state.app.lang;

export const {
  setLang,
  setIsWidthHideFilter,
  setShowFilter,
  setShowContactPopup,
  setIsMobile,
  setIsRealMobile,
  setIsSmallScreen,
} = appSlice.actions;

export default appSlice.reducer;
