import React from "react";

// "Re" stands for residence
// "Pa" stands for passport

// Residence - UK
export const ReUkInvReq: React.FC = () => {
  return <div>最低投资额：5万英镑(约45万人⺠币)，创办注册于英国的公司</div>;
};

export const ReUkProjReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>具有创新型、可行性、可拓展性</li>
      <li>
        项目背书: 由英国内政部认可的背书机构(endorsing
        body)中其中的一家，评估和认可项目，并为该项目背书
      </li>
    </ul>
  );
};

export const ReUkPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>
        语言要求明确: 英语语言能力达到B2要求(相当于雅思移
        ⺠类5.5分)，如果申请人持有英国或全英文授课大学本科以及以上学历，则无需英语语言水平测试
      </li>
      <li>主申请人年满18周岁，可携带配偶及18岁以下子女</li>
    </ul>
  );
};

// Residence - UAE
export const ReUaeInvReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>在阿联酋拥有价值至少为 2,000,000 迪拉姆(相当于400万人⺠币)的房产</li>
      <li>该物业须完工或至少完成 50% 的施工</li>
    </ul>
  );
};

export const ReUaePerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>18-70周岁</li>
      <li>申请人需持有有效期6个月以上的护照</li>
    </ul>
  );
};

// Residence - Greece
export const ReGreeceInvReq: React.FC = () => {
  return (
    <ul className="list-alpha list-inside">
      <li>
        <p>25万欧元投资⻔槛</p>
        <ul className="list-decimal list-inside">
          <li>投资政府保护性建筑，并在居留许可首卡五年内完成建筑物的修复;</li>
          <li>
            投资其它由商业性质建筑改造为住宅性质的房产(可以是商铺、工厂酒店等，如是工业性质，许可在变更前要有5年的
            空置期)。且许可变更须在提交⻩金居留申请之前完成。
          </li>
        </ul>
      </li>
      <li>
        <p>40万欧元投资⻔槛</p>
        <p>希腊其他地区，房产最低投资额为40万欧</p>
      </li>
      <li>
        <p>80万欧元投资⻔槛</p>
        <p>
          阿提卡大区、塞萨洛尼基、米克诺斯、圣托尼里以及人口超过
          3,100人的岛屿地区，房产的投资⻔槛为80万欧
        </p>
      </li>
    </ul>
  );
};

export const ReGreecePerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>年满18岁</li>
      <li>购买医疗保险</li>
      <li>无犯罪记录</li>
    </ul>
  );
};

export const ReGreeceDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>21 岁以下子女</li>
      <li>父母</li>
      <li>配偶</li>
    </ul>
  );
};

// Passport - Turkey
export const PaTurkeyInvReq: React.FC = () => {
  return <div>购买40万美金以上房产持有3年</div>;
};

export const PaTurkeyPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>年满18周岁以上</li>
      <li>无犯罪记录</li>
    </ul>
  );
};

export const PaTurkeyDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>配偶</li>
      <li>18岁以下子女</li>
    </ul>
  );
};

// Residence - Malta
export const ReMaltaPolicyChangeNote: React.FC = () => {
  return (
    <div>
      <p>
        新政策将于2025年1月1日生效，锁定老政策:
        2024年12月31日前递交如下文件，并于2025年3月28日前补充剩余所有必备申请文件。
      </p>
      <ul className="list-decimal list-inside">
        <li>MPRP表1</li>
        <li>MPRP表10 (所有申请人)</li>
        <li>POA</li>
        <li>护照认证件 (所有申请人)</li>
        <li>
          主申请人签署的声明(声明后续政府款项将从哪个账户
          支付，并详细列明账户信息)
        </li>
      </ul>
    </div>
  );
};
export const ReMaltaInvReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>
        政府捐款: 8万欧元 <span>[老政策:6.8万欧元]</span> (购买物业方式)或者
        11万欧元<span>[老政策:9.8万欧元]</span> (租赁物业方式){" "}
        <span className="note">
          [注:
          捐款中的5万欧元，递交后1个月内支付1.5万欧元，原批后2个月内支付3.5万欧元为政府行政管理费]
        </span>{" "}
        <span>
          [老政策:政府行政管理费4万欧元，递交后1个月内支付1万欧元，原批后2个月内支付3万欧元]
        </span>
      </li>

      <li>
        NGO捐款: 已取消 <span>[老政策:2,000欧元]</span>{" "}
      </li>

      <li>
        物业: 购买至少37.5万欧元{" "}
        <span>[老政策:主岛的35万欧元;戈佐岛的30万欧元]</span>
        以上的住宅物业或租赁住宅物业，租金不低于 1.4万欧元/年
        <span>[老政策:主岛的1.2万欧元/年;戈佐岛的1万欧元/年]</span>
        <span className="note">
          [注:此物业必须持有5年，期间不能租赁或转租给第三方]
        </span>{" "}
      </li>

      <li>
        保险: 购买有效的覆盖⻢耳他的医疗保险，每名家庭成员的保额为至少3万欧元{" "}
      </li>

      <li>
        需为随行的配偶、子女、父母、祖父母缴纳每人1万欧元的政府费用(原批后2个月内支付5000欧行政管理费，原批后8个月内支付5000欧捐款)
        <span>[老政策:配偶子女免费。父母祖父母每人7500欧]</span>
      </li>
    </ul>
  );
};

export const ReMaltaPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>年满18周岁</li>
      <li>无犯罪/失信记录</li>
      <li>
        <p>净资产 [新政策:符合其一即可]</p>
        <ul className="list-disc list-inside">
          <li>超过50万欧(其中至少15万欧为流动资产)</li>
          <li>[新]超过65万欧(其中至少7.5万欧为流动资产)</li>
        </ul>
      </li>
    </ul>
  );
};

export const ReMaltaDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>配偶</li>
      <li>
        未婚、经济依赖主申的成年子女作为附属申请人，最高年龄上 限为29岁{" "}
        <span>[老政策:年龄无上限]</span>
      </li>
      <li>主副申请人经济不独立的父母以及祖父母(无最低年龄要求)</li>
    </ul>
  );
};

// Passport - Malta
export const PaMaltaInvReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>
        向⻢耳他国家发展基金捐款60万欧元/75万欧元，附属申请人增加5万欧元/人，分别对应36/12个月的审理周期
      </li>
      <li>
        购买价值70万欧元以上的居住用房产，持有至少五年;或租赁一套住宅不动产，批复后租期为5年，每年房租至少1.6万欧元(E卡1-3年，批复后5年)
      </li>
      <li>捐款1万欧元到⻢耳他慈善机构</li>
    </ul>
  );
};

export const PaMaltaPerReq: React.FC = () => {
  return (
    <ul>
      <li>年满18周岁</li>
      <li>提供无犯罪证明，通过尽职调查</li>
      <li>进行体检:证明申请人身体状况良好，无传染性疾病</li>
      <li>
        在递交申请前没有任何类型的美国、加拿大、澳洲、申根、欧盟等⻢耳他护照免签的国家的拒签记录
      </li>
      <li>
        主申请人名下净资产，建议至少为3千万-4千万人⺠币，这个资金的目的在于可以向移⺠局证明申请人是有足够的财力来进行后续捐款的，资产无需税后收入，仅需合理解释来源即可，比如名下有公司，是通过公司来获得的收入等，具体方案签约后详细制定
      </li>
    </ul>
  );
};

export const PaMaltaDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>配偶: 合法婚姻或事实婚姻(可接受同性婚姻)</li>
      <li>
        子女:
        29周岁以下未婚经济不独立(在读是好的经济依赖证明，但不是强制的，如果真的毕业了，只要不工作没有收入，父母每个月有支付生活费等，都可以作为辅助证明真实是经济依赖，不独立的)，残疾子女无年龄限制
      </li>
      <li>父母/祖父母(包括配偶的父母和祖父母):55周岁以上且经济依赖主申请人</li>
      <li>
        主申请人之可随行直系亲属已与环球签署⻢耳他项目合同并在办理中，主申请人因不能满足经济依赖条件而作为随行申请人，需要拆分2户单独申请的
      </li>
    </ul>
  );
};

// Residence - Spain
export const ReSpainInvReq: React.FC = () => {
  return (
    <div>
      购买50万欧元以上房产(房产类型不限，包括:公寓、联排别墅、独栋别墅、商铺等)，也可以同时购买多个房产，只要房产总净值达50万欧元以上即可
    </div>
  );
};

export const ReSpainPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>年满18岁</li>
      <li>无犯罪记录</li>
    </ul>
  );
};

export const ReSpainOtherReqPR: React.FC = () => {
  return (
    <div>
      <h4>申请永居要求</h4>
      <ul className="list-decimal list-inside">
        <li>5年内住满4年2个月，单次离境不超过6个月</li>
        <li>在⻄班牙无犯罪记录</li>
      </ul>
    </div>
  );
};
export const ReSpainOtherReqCitizenship: React.FC = () => {
  return (
    <div>
      <h4>申请入籍要求</h4>
      <ul className="list-decimal list-inside">
        <li>连续合法居住10年，10年内住满8年零4个月，每年住满半年</li>
        <li>⻄班牙语入籍考试</li>
        <li>在⻄班牙无犯罪记录</li>
      </ul>
    </div>
  );
};

// Residence - Portugal
export const RePortugalInvReq: React.FC = () => {
  return (
    <div>投资不低于50万欧元到成立于葡萄牙的基金，投资期至少保持5 年以上。</div>
  );
};

export const RePortugalPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>主申请人年满18周岁</li>
      <li>无犯罪记录</li>
      <li>购买私人健康保险(不同地区SEF要求不同，以递交时要求为准)</li>
    </ul>
  );
};

export const RePortugalDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>主申合法配偶</li>
      <li>18岁以下子女</li>
      <li>18周岁以上未婚在读经济不独立子女</li>
      <li>主申请人父母及合法配偶父母</li>
    </ul>
  );
};

// Residence - Singapore
export const ReSingaporeInvReq: React.FC = () => {
  return (
    <div>
      至少1000万新币起流动资产，并且承诺在两年内管理的资产规 模达到2000万新币{" "}
    </div>
  );
};

export const ReSingaporePerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>主申年龄在21岁或以上(合理且有能力管理资产)</li>
      <li>能完整解释财富累积过程和资金来源</li>
      <li>无犯罪记录或不良记录</li>
      <li>每年达到20万新币的运营支出</li>
      <li>每个架构需要至少2个资产管理人</li>
      <li>10%的总管理资产需要投在新加坡本地</li>
    </ul>
  );
};

// Passport - Vanuatu
export const PaVanuatuInvReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>
        <p>向瓦国政府捐款:</p>
        <ul className="list-alpha list-inside">
          <li>单人捐款80000美金</li>
          <li>夫妻捐款100000美金</li>
          <li>一家三口(孩子未满18周岁)捐款115000美金</li>
          <li>
            一家四口(孩子未满18周岁)捐款130000美金(每额外增加一位申请人，增加10000美金)
          </li>
        </ul>
      </li>
      <li>
        <p>25万美金以上资产证明</p>
      </li>
    </ul>
  );
};

export const PaVanuatuPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>主申请人年满18周岁</li>
      <li>无犯罪记录(16周岁以上申请人都需提供)</li>
    </ul>
  );
};
export const PaVanuatuDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>配偶</li>
      <li>26周岁以下未婚在读子女</li>
      <li>50周岁以上的双方父母</li>
    </ul>
  );
};

// Passport - Saint Kitts and Nevis
export const PaSaintKittsAndNevisInvReq: React.FC = () => {
  return (
    <ul className="list-alpha list-inside">
      <li>房产投资选项：325,000美元起</li>
      <li>捐款选项：250,000美金起</li>
    </ul>
  );
};

export const PaSaintKittsAndNevisPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>主申请人年满18周岁</li>
      <li>无犯罪记录</li>
    </ul>
  );
};
export const PaSaintKittsAndNevisDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>主申请人的配偶</li>
      <li>18 岁以下的子女</li>
      <li>18-25 岁之间，经济依赖于主申且在正规学校全日制读书的学生</li>
      <li>18 岁以上身体或精神有残疾的子女</li>
      <li>主申或配偶的父母，55岁(含)以上，与主申同住，且经济依附于主申</li>
    </ul>
  );
};

// Passport - Antigua and Barbuda
export const PaAntiguaAndBarbudaInvReq: React.FC = () => {
  return (
    <ul className="list-alpha list-inside">
      <li>房产投资选项:400,000美金起</li>
      <li>捐款选项:230,000美金起</li>
    </ul>
  );
};
export const PaAntiguaAndBarbudaPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>年满18周岁</li>
      <li>无犯罪记录</li>
    </ul>
  );
};
export const PaAntiguaAndBarbudaDepReq: React.FC = () => {
  return (
    <div>
      <ul className="list-decimal list-inside">
        <li>配偶</li>
        <li>主申和配偶30岁及以下子女(无需在读证明，无需经济依 赖，可以已婚)</li>
        <li>主申和配偶的兄弟姐妹(不限年龄，离婚/丧偶/未婚，有无子女皆可)</li>
        <li>主申和配偶的55岁及以上父母，祖父母</li>
        <li>主申和配偶的30岁及以下儿媳，女婿</li>
        <li>主申和配偶的18岁及以下孙辈</li>
      </ul>
    </div>
  );
};

// Passport - Dominica
export const PaDominicaInvReq: React.FC = () => {
  return (
    <div>
      <p>房产投资:</p>
      <ul className="list-decimal list-inside">
        <li>单人：200,000美金</li>
        <li>全家四人以内：250,000美金</li>
      </ul>
    </div>
  );
};
export const PaDominicaPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>18岁以上</li>
      <li>无犯罪证明</li>
    </ul>
  );
};

// Passport - Grenada
export const PaGrenadaInvReq: React.FC = () => {
  return <div>捐款：全家四人以内235,000美金</div>;
};
export const PaGrenadaPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>年满18周岁</li>
      <li>无犯罪记录</li>
    </ul>
  );
};
export const PaGrenadaDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>配偶</li>
      <li>30周岁及以下未婚子女，无需在读</li>
      <li>主副申请人的父母、祖/外祖父母，经济依附于申请人</li>
      <li>
        主副申请人的18周岁以上，未婚无子女的兄弟姐妹(同父同母/同父异母/同母异父)
      </li>
    </ul>
  );
};

// Passport - St Lucia
export const PaStLuciaInvReq: React.FC = () => {
  return <div>捐款:全家四人以内240,000美金</div>;
};

export const PaStLuciaPerReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>主申请人年满18周岁</li>
      <li>提供无犯罪证明</li>
      <li>满足投资要求</li>
    </ul>
  );
};

export const PaStLuciaDepReq: React.FC = () => {
  return (
    <ul className="list-decimal list-inside">
      <li>30周岁以下子女(经济依赖)</li>
      <li>55周岁以上主申和配偶双方父母</li>
      <li>主申未满18周岁的弟弟妹妹可随行</li>
    </ul>
  );
};

export const PaStLuciaReunionReq: React.FC = () => {
  return (
    <div>
      <h4>护照团聚要求</h4>
      <ul>
        <li>主申拿到护照后的新生子女</li>
        <li>主申拿到护照后的新婚配偶</li>
        <li>
          主申在拿到护照前符合条件的附属申请人，可在主申拿到护照后的5年内申请入籍团聚
        </li>
      </ul>
    </div>
  );
};
