import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Header.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { setLayoutValue } from "../../features/app/layoutsSlice";
import { getLang, setLang } from "../../features/app/appSlice";
import { getIsMobile, getIsSmallScreen } from "../../features/app/appSlice";
import {
  nvfoLogoBlue,
  teamBackground1,
  teamBackground2,
  teamBackground3,
} from "../../assets/imgExport";
import {
  IconAccount,
  IconCharity,
  IconCheckbook,
  IconClose,
  IconCorporate,
  IconEdu,
  IconMenu,
} from "../../assets/svgExport";
import {
  iconFlagUK,
  iconFlagUAE,
  iconFlagGreece,
  iconFlagTurkey,
  iconFlagMalta,
  iconFlagSpain,
  iconFlagPortugal,
  iconFlagSingapore,
  iconFlagVanuatu,
  iconFlagStKitts,
  iconFlagStLucia,
  iconFlagDominica,
  iconFlagGrenada,
  iconFlagAntigua,
} from "../../assets/imgExport";

function Header(props: any) {
  const [showMenu, setShowMenu] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const lang = useSelector(getLang);
  const isSmallScreen = useSelector(getIsSmallScreen);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const [expandedHeaders, setExpandedHeaders] = useState<{
    [key: string]: boolean;
  }>({});
  const [showExpanded, setShowExpanded] = useState(false);
  const navigate = useNavigate();

  const hideMobileHeader = (e: any) => {
    console.log("hideMobileHeader");
    e.stopPropagation();
    setShowMenu(false);
    setExpandedHeaders({});
    setShowExpanded(false);
    if (document.querySelector("html")) {
      document.querySelector("html")!.style.overflow = "visible";
    }
  };

  const toggleMenu = (bool: boolean) => {
    if (document.querySelector("html")) {
      document.querySelector("html")!.style.overflow = bool
        ? "hidden"
        : "visible";
    }
    setShowMenu(bool);
    setExpandedHeaders({});
    setShowExpanded(false);
  };

  function ExpandableHeader({
    title,
    headerKey,
  }: {
    title: any;
    headerKey: string;
  }) {
    return (
      <p
        id={`header-${headerKey}`}
        className={`relative h-full flex items-center font-medium hover:cursor-default ${
          isSmallScreen ? "" : "px-6 py-4"
        } ${
          currentPath.includes("/" + headerKey) || expandedHeaders[headerKey]
            ? "text-secondary-light"
            : ""
        }`}
        onMouseEnter={(e: any) => {
          if (!isSmallScreen && title.zh !== "关于我们") {
            setExpandedHeaders({
              program: headerKey === "program",
              wealth: headerKey === "wealth",
              legacy: headerKey === "legacy",
            });
            setShowExpanded(true);
          }
        }}
      >
        {title.zh}
      </p>
    );
  }

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        if (headerRef.current && headerRef.current.offsetHeight > 0) {
          dispatch(
            setLayoutValue({
              name: "headerHeight",
              value:
                window.innerWidth < 650 ? 60 : headerRef.current.offsetHeight,
            })
          );
        }
      }, 300);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, [dispatch]);

  return (
    <>
      <header
        className={`fixed left-0 w-screen z-[9999] bg-white font-medium`}
        ref={headerRef}
        onMouseLeave={() => {
          setShowExpanded(false);
          setExpandedHeaders({});
        }}
      >
        {/* Top section - Logo and Navigation */}
        <div className="">
          <nav className="flex justify-between items-center">
            <NavLink
              id="header-logo"
              to="/"
              className="pl-10 hover:cursor-pointer"
              onClick={hideMobileHeader}
            >
              <img
                className={`${isSmallScreen ? "h-5" : "h-8"} object-contain`}
                src={nvfoLogoBlue}
                alt="nvfo-logo"
              />
            </NavLink>

            {!isSmallScreen && (
              <div className="flex">
                <ExpandableHeader
                  title={{ en: "Program", zh: "身份规划" }}
                  headerKey="program"
                />
                <ExpandableHeader
                  title={{ en: "Wealth Management", zh: "财富规划" }}
                  headerKey="wealth"
                />
                <ExpandableHeader
                  title={{ en: "Legacy Planning", zh: "传承规划" }}
                  headerKey="legacy"
                />
                <HashLink
                  id="header-about"
                  className={`${
                    isSmallScreen ? "" : "px-6 py-4"
                  } hover:text-secondary-light h-full flex items-center
                  ${currentPath.includes("/about") ? "text-main" : ""}`}
                  to="/#about-section"
                  onClick={hideMobileHeader}
                >
                  <p>{"关于我们"}</p>
                </HashLink>
              </div>
            )}

            {isSmallScreen && (
              <div onClick={() => toggleMenu(!showMenu)}>
                {showMenu ? (
                  <IconClose height="20" width="20" />
                ) : (
                  <IconMenu height="20" width="20" />
                )}
              </div>
            )}
          </nav>
        </div>

        {/* Expanded section */}
        <div
          className={`transition-[height] duration-300 ease-in-out overflow-hidden bg-white border-t shadow-xl shadow-secondary-dark/5 `}
          style={{
            height: showExpanded
              ? expandedHeaders["program"]
                ? "450px"
                : "200px"
              : "0",
            visibility: showExpanded ? "visible" : "hidden",
          }}
        >
          <div className="border-b-[0.5px] border-b-gray-300/30 flex gap-4 h-full">
            <div className="h-full">
              {expandedHeaders["program"] && (
                <div className="h-full w-[200px] relative">
                  <div className="relative h-full w-full">
                    <img
                      className="h-full w-full object-cover brightness-75"
                      src={teamBackground1}
                      alt="team"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                  </div>

                  <p className="font-main-sans absolute bottom-0 left-0 p-10  text-white flex flex-col gap-2">
                    <span className="text-2xl font-semibold">身份规划</span>
                    <span className="text-sm font-normal leading-relaxed opacity-70">
                      身份规划助您通过多国居留与护照项目实现身份升级，畅享全球化生活与商业机遇。
                    </span>
                  </p>
                </div>
              )}
              {expandedHeaders["wealth"] && (
                <div className="h-full w-[400px] relative">
                  <div className="relative h-full w-full">
                    <img
                      className="h-full w-full object-cover brightness-75"
                      src={teamBackground3}
                      alt="team"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                  </div>

                  <p className="font-main-sans absolute bottom-0 left-0 p-10  text-white flex flex-col gap-2">
                    <span className="text-2xl font-semibold">财富规划</span>
                    <span className="text-sm font-normal leading-relaxed opacity-70">
                      财富规划专注于帮助客户优化全球资产配置，合理利用税务优惠政策，搭建高效商业架构，提升跨境经营能力，实现财富的合规增值与全球优化管理。
                    </span>
                  </p>
                </div>
              )}
              {expandedHeaders["legacy"] && (
                <div className="h-full w-[400px] relative">
                  <div className="relative h-full w-full">
                    <img
                      className="h-full w-full object-cover brightness-75"
                      src={teamBackground2}
                      alt="team"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                  </div>

                  <p className="font-main-sans absolute bottom-0 left-0 p-10  text-white flex flex-col gap-2">
                    <span className="text-2xl font-semibold">传承规划</span>
                    <span className="text-sm font-normal leading-relaxed opacity-70">
                      传承规划通过家族信托、教育支持及慈善公益，确保财富安全传承，同时兼顾社会责任与家族价值延续，为后代未来发展和家族精神的延续奠定基础。
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div className="p-10">
              {expandedHeaders["program"] && (
                <div className="flex gap-20">
                  {["居留项目", "护照项目"].map((desc, index) => (
                    <div
                      key={index}
                      className={`text-secondary-dark font-normal bg-white whitespace-nowrap flex flex-col gap-4`}
                    >
                      <p className="text-gray-400 font-normal text-[12px] font-sans">
                        {desc}
                      </p>
                      {[
                        [
                          {
                            to: "/program/residence/uk",
                            en: "UK Innovator Visa",
                            zh: "英国创新者签证",
                            icon: <img src={iconFlagUK} alt="uk" />,
                          },
                          {
                            to: "/program/residence/uae",
                            en: "UAE Investor Visa",
                            zh: "阿联酋投资移民",
                            icon: <img src={iconFlagUAE} alt="uae" />,
                          },
                          {
                            to: "/program/residence/greece",
                            en: "Greek Golden Visa",
                            zh: "希腊投资移民",
                            icon: <img src={iconFlagGreece} alt="greece" />,
                          },
                          {
                            to: "/program/residence/malta",
                            en: "Malta Residence Permit",
                            zh: "马耳他投资移民",
                            icon: <img src={iconFlagMalta} alt="malta" />,
                          },
                          {
                            to: "/program/residence/spain",
                            en: "Spanish Investor Visa",
                            zh: "西班牙投资移民",
                            icon: <img src={iconFlagSpain} alt="spain" />,
                          },
                          {
                            to: "/program/residence/portugal",
                            en: "Portuguese Golden Visa",
                            zh: "葡萄牙黄金居留",
                            icon: <img src={iconFlagPortugal} alt="portugal" />,
                          },
                          {
                            to: "/program/residence/singapore",
                            en: "Singapore Work Visa",
                            zh: "新加坡工作签证",
                            icon: (
                              <img src={iconFlagSingapore} alt="singapore" />
                            ),
                          },
                        ],
                        [
                          {
                            to: "/program/passport/turkey",
                            en: "Turkey Citizenship by Investment",
                            zh: "土耳其护照",
                            icon: <img src={iconFlagTurkey} alt="turkey" />,
                          },
                          {
                            to: "/program/passport/malta",
                            en: "Malta Citizenship by Investment",
                            zh: "马耳他护照",
                            icon: <img src={iconFlagMalta} alt="malta" />,
                          },
                          {
                            to: "/program/passport/vanuatu",
                            en: "Vanuatu Citizenship by Investment",
                            zh: "瓦努阿图护照",
                            icon: <img src={iconFlagVanuatu} alt="vanuatu" />,
                          },
                          {
                            to: "/program/passport/st-kitts-and-nevis",
                            en: "St Kitts Citizenship by Investment",
                            zh: "圣基茨护照",
                            icon: <img src={iconFlagStKitts} alt="stkitts" />,
                          },
                          {
                            to: "/program/passport/antigua-and-barbuda",
                            en: "Antigua Citizenship by Investment",
                            zh: "安提瓜护照",
                            icon: <img src={iconFlagAntigua} alt="antigua" />,
                          },
                          {
                            to: "/program/passport/dominica",
                            en: "Dominica Citizenship by Investment",
                            zh: "多米尼克护照",
                            icon: <img src={iconFlagDominica} alt="dominica" />,
                          },
                          {
                            to: "/program/passport/st-lucia",
                            en: "St Lucia Citizenship by Investment",
                            zh: "圣卢西亚护照",
                            icon: <img src={iconFlagStLucia} alt="stlucia" />,
                          },
                          {
                            to: "/program/passport/grenada",
                            en: "Grenada Citizenship by Investment",
                            zh: "格林纳达护照",
                            icon: <img src={iconFlagGrenada} alt="grenada" />,
                          },
                        ],
                      ][index]?.map((item, itemIndex) => (
                        <NavLink
                          key={itemIndex}
                          to={item.to}
                          onClick={hideMobileHeader}
                          className="flex gap-3 items-center hover:opacity-80 hover:cursor-pointer"
                        >
                          <div className="bg-main/10 rounded-md p-2 w-10 hover:cursor-pointer">
                            {item.icon}
                          </div>
                          <p className="hover:cursor-pointer">{item.zh}</p>
                        </NavLink>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {expandedHeaders["wealth"] && (
                <div className="flex flex-col gap-5">
                  {[0].map((index) => (
                    <div key={index} className="flex flex-col gap-3">
                      {[
                        [
                          {
                            to: "/wealth#tax",
                            en: "Tax Planning",
                            zh: "税务规划",
                            icon: (
                              <IconAccount
                                fill="3B4B5F"
                                width="16"
                                height="16"
                              />
                            ),
                          },
                          {
                            to: "/wealth#company",
                            en: "Company Formation",
                            zh: "公司规划",
                            icon: (
                              <IconCorporate
                                fill="#3B4B5F"
                                width="16"
                                height="16"
                              />
                            ),
                          },
                        ],
                      ][index]?.map((item, itemIndex) => (
                        <HashLink
                          key={itemIndex}
                          to={item.to}
                          onClick={hideMobileHeader}
                          className="flex gap-3 items-center hover:opacity-80 hover:cursor-pointer"
                        >
                          <div className="bg-main/10 rounded-md p-2  hover:cursor-pointer">
                            {item.icon}
                          </div>
                          <p className="hover:cursor-pointer">{item.zh}</p>
                        </HashLink>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {expandedHeaders["legacy"] && (
                <div className="flex flex-col gap-5">
                  {[0].map((index) => (
                    <div key={index} className="flex flex-col gap-3">
                      {[
                        [
                          {
                            to: "/legacy#trust",
                            en: "Family Trust",
                            zh: "家族信托",
                            icon: (
                              <IconCheckbook
                                fill="#3B4B5F"
                                width="16"
                                height="16"
                              />
                            ),
                          },
                          {
                            to: "/legacy#education",
                            en: "Education Planning",
                            zh: "教育规划",
                            icon: (
                              <IconEdu fill="#3B4B5F" width="16" height="16" />
                            ),
                          },
                          {
                            to: "/legacy#charity",
                            en: "Charitable Trust",
                            zh: "慈善公益",
                            icon: (
                              <IconCharity
                                fill="#3B4B5F"
                                width="16"
                                height="16"
                              />
                            ),
                          },
                        ],
                      ][index]?.map((item, itemIndex) => (
                        <HashLink
                          key={itemIndex}
                          to={item.to}
                          onClick={hideMobileHeader}
                          className="flex gap-3 items-center hover:opacity-80 hover:cursor-pointer"
                        >
                          <div className="bg-main/10 rounded-md p-2 hover:cursor-pointer">
                            {item.icon}
                          </div>
                          <p className="hover:cursor-pointer">{item.zh}</p>
                        </HashLink>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
