// src/components/HtmlOverflowManager.tsx
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setIsMobile,
  setIsRealMobile,
  setIsSmallScreen,
  setIsWidthHideFilter,
} from "../../features/app/appSlice";
import { AppDispatch } from "../store";
import { debounce } from "../../util/debounce";

const ResizeManager: React.FC = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const handleResize = debounce(() => {
      dispatch(setIsMobile(window.innerWidth < 650));
      dispatch(setIsRealMobile(window.innerWidth < 500));
      dispatch(setIsSmallScreen(window.innerWidth < 870));
    }, 300);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsWidthHideFilter(window.innerWidth < 920);
  }, []);

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setIsWidthHideFilter(window.innerWidth < 920);
      }, 300);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, []);

  return null; // This component doesn't render anything
});

export default ResizeManager;
