import "./App.css";
import React, { useEffect } from "react";
import { AppRoutes } from "./AppRoutes";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store";
// import { checkPermission } from "../api/user";
import HtmlOverflowManager from "./managers/HtmlOverflowManager";
import { preloadImagesIfNeeded } from "../util/loadResources/imagePreloader";
import ResizeManager from "./managers/ResizeManager";
import UserSettingManager from "./managers/UserSettingManager";

export const App = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    preloadImagesIfNeeded();
  }, []);

  // TODO: change to auth user, and set current user
  useEffect(() => {
    (async () => {
      try {
        // const response = await checkPermission();
        // if (response.status === 200) {
        //   dispatch(setPermission(5));
        //   return;
        // }
      } catch (error) {
        // console.error(error);
      }
    })();
  }, []);

  return (
    <>
      <UserSettingManager />
      <ResizeManager />
      <HtmlOverflowManager />
      <AppRoutes />
    </>
  );
};
