import React from "react";
import { HashLink } from "react-router-hash-link";
import { getLang } from "../../features/app/appSlice";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Logo from "../../components/Logo/Logo";

const qrFollowUs = require("../../assets/media/logo/wechat-official-qrcode.jpg");
const qrChatToUs = require("../../assets/media/logo/wechat-chat-qrcode.jpg");
const nvfoLogo = require("../../assets/media/logo/nvfo-logo-white.png");
const oisc = require("../../assets/media/logo/oisc-logo-white.png");
const ilpa = require("../../assets/media/logo/ilpa-logo-white.png");
const ciol = require("../../assets/media/logo/ciol-logo-white.png");

function Footer(props: any) {
  const lang = useSelector(getLang);

  return (
    <footer className={`mt-auto p-10 text-white bg-main flex flex-col gap-20`}>
      <div className="flex justify-between gap-10">
        <div>
          <img
            className="h-10 mb-10"
            src={nvfoLogo}
            alt="New Vision Family Office"
          />
          <div className="font-thin opacity-90">
            <p>国际视野 本地专识</p>
            <p>
              Global Vision
              <br />
              Local Expertise
            </p>
          </div>
          <ul className="flex gap-2 h-16 mt-10">
            <li>
              <img className="h-full" src={oisc} alt="OISC" />
            </li>
            <li>
              <img className="h-full" src={ilpa} alt="ILPA" />
            </li>
            <li>
              <img className="h-full" src={ciol} alt="CIOL" />
            </li>
          </ul>
        </div>
        <div className="flex gap-6 font-light text-white/90">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-white mb-2">身份规划</p>
            <NavLink to="/">居留项目</NavLink>
            <NavLink to="/">护照项目</NavLink>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-white mb-2">财富规划</p>
            <NavLink to="/">税务规划</NavLink>
            <NavLink to="/">公司服务</NavLink>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-white mb-2">传承规划</p>
            <NavLink to="/">家族信托</NavLink>
            <NavLink to="/">教育规划</NavLink>
            <NavLink to="/">慈善公益</NavLink>
          </div>
          <div className="flex flex-col gap-1">
            <NavLink to="/" className="font-bold text-white mb-2">
              关于我们
            </NavLink>
            <NavLink to="/" className="font-bold text-white">
              订阅我们
            </NavLink>
          </div>
        </div>
        <div className="text-right flex flex-col gap-10 items-end">
          <div className="h-20 flex gap-2 mb-4 justify-end">
            <div className="flex flex-col items-center gap-1">
              <img
                className="h-full"
                src={qrFollowUs}
                alt="New Vision Family Office"
              />
              <p className="text-xs">关注我们</p>
            </div>
            <div className="flex flex-col items-center gap-1">
              <img
                className="h-full"
                src={qrChatToUs}
                alt="New Vision Family Office"
              />
              <p className="text-xs">联系我们</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 font-light">
            <p>
              <a href="tel:+447497999999">+44 (0) 20 3907 4024</a>
            </p>
            <p>
              <a href="mailto:fo@newvgroup.com">fo@newvgroup.com</a>
            </p>
            <p>
              16 Berkeley Street, Mayfair
              <br />
              London, W1J 8DZ
              <br />
              United Kingdom
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <ul className="flex w-full gap-4 pb-2 mb-2 text-white/70 border-b-[0.5px] border-white/10 justify-center">
          <li>
            <NavLink to="/policy/terms-of-use">Terms of use</NavLink>
          </li>
          <li>
            <NavLink to="/policy/privacy-policy">Privacy Policy</NavLink>
          </li>
          <li>
            <NavLink to="/policy/cookie-policy">Cookie Policy</NavLink>
          </li>
        </ul>
        <p className="text-center text-xs text-white/30 max-w-[500px]">
          &copy;2025 New Vision Family Office. New Vision Family Office 是 New
          Vision International Group Limited
          新愿景国际集团有限公司的商业名称，注册于英格兰及威尔士，公司编号为13029696。
        </p>
      </div>
    </footer>
  );
}

export default Footer;
