import React, { useState } from "react";
import {
  IconAdd,
  IconArrowRight,
  IconBadge,
  IconCalendar,
  IconCurrency,
  IconGlobe,
  IconRemove,
} from "../../assets/svgExport";

export default function ProgramPage({ programData }: { programData: any }) {
  const programFullName =
    programData.country + programData.immigrationType.title;

  const amount = programData.investmentAmount;
  const investmentAmount =
    amount.set +
    (amount.optional ? "/" + amount.optional : "") +
    (amount.above ? "起" : "");

  function HeroSection() {
    return (
      <div className="relative h-96 w-full overflow-hidden flex items-center justify-start px-20">
        <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/20 z-[1]" />
        <img
          className="absolute bottom-[-35%] right-0 w-screen object-cover"
          src={programData.image.background}
          alt={programFullName}
        />
        <div className="relative z-10 text-white">
          <h1 className="text-inherit flex items-center">
            <span className="font-bold">{programData.country}</span>
            &nbsp;
            <span className="font-sans">
              {programData.immigrationType.title}
            </span>
          </h1>
          <div className="h-[0.125rem] w-40 bg-white/70 my-5"></div>
          <p className="w-[50%] opacity-90 leading-relaxed">
            {programData.description}
          </p>
        </div>
        <div className="absolute top-5 right-5 z-10 text-white flex gap-3 font-semibold">
          <p className="bg-white/25 rounded-full px-5 py-1 flex items-center gap-1 shadow-3xl shadow-black/5">
            <IconCurrency width={20} height={20} fill="#fff" />
            {programData.currency.symbol}
            {investmentAmount}
          </p>
          <p className="bg-white/25 rounded-full px-5 py-1 flex items-center gap-1 shadow-3xl shadow-black/5">
            <IconCalendar width={20} height={20} fill="#fff" />
            {programData.process.duration.min}-
            {programData.process.duration.max}
            {programData.process.duration.unit}
          </p>
        </div>
      </div>
    );
  }

  function CountryOverview() {
    return (
      <div className="py-32 relative">
        <div className="w-[60%] relative z-10">
          <div className="flex items-center gap-4 mb-10">
            <h2>{programData.country}国家概况</h2>
            <img
              className="mt-1 h-8 shadow-xl border-2 border-white rounded-sm object-cover"
              src={programData.image.flag}
              alt={programData.country + "'s flag"}
            />
          </div>

          <div className="leading-relaxed">
            {programData.countryOverview.description.length > 0 &&
              programData.countryOverview.description.map(
                (item: string, index: number) => (
                  <p
                    key={
                      "country-overview-" + programData.countryEn + "-" + index
                    }
                    className="mb-3"
                  >
                    {item}
                  </p>
                )
              )}
          </div>
          <ul className="flex flex-col gap-2 mt-16">
            <li className="flex items-center gap-2">
              <IconGlobe />
              <span className="font-bold">
                官方语言：{programData.countryOverview.officialLanguage}
              </span>
            </li>
            <li className="flex items-center gap-2">
              <IconCurrency />
              <span className="font-bold">
                官方货币：{programData.countryOverview.officialCurrency}
              </span>
            </li>
            <li className="flex items-center gap-2">
              <IconBadge />
              <span className="font-bold">
                免签国家：{programData.countryOverview.visaFree}个
              </span>
            </li>
          </ul>
        </div>
        <div className="w-[50%] absolute -right-20 top-0 h-full">
          <img
            className="h-full w-full object-cover"
            src={programData.image.map}
            alt={programData.country + "'s map"}
          />
          <div className="absolute inset-0  bg-gradient-to-r from-gray-100 to-transparent" />
        </div>
      </div>
    );
  }

  function Requirements() {
    const data = [
      {
        title: "投资要求",
        requirement: programData.requirements.investmentRequirement,
      },
      {
        title: "项目要求",
        requirement: programData.requirements.projectRequirement,
      },
      {
        title: "申请人",
        requirement: programData.requirements.personalRequirement,
      },
      {
        title: "附属申请人",
        requirement: programData.requirements.dependentsRequirement,
      },
      {
        title: "其他要求",
        requirement: programData.requirements.otherRequirement,
      },
    ];
    return (
      <div className="my-24 flex">
        <h2 className="leading-relaxed w-[45%]">
          <span>谁可以申请</span>
          <br />
          <span>{programFullName}？</span>
        </h2>
        <div className="w-[55%]">
          {data.length > 0 &&
            data.map(
              (item, index) =>
                item.requirement &&
                (Array.isArray(item.requirement) ? (
                  item.requirement.map(
                    (item1: React.ReactNode, index1: number) => (
                      <>
                        <Requirement
                          key={"requirement-" + index1}
                          title={item.title}
                          requirement={item1}
                        />
                      </>
                    )
                  )
                ) : (
                  <Requirement
                    key={"requirement-" + index}
                    title={item.title}
                    requirement={item.requirement}
                  />
                ))
            )}
        </div>
      </div>
    );
  }

  // helper
  function Requirement({
    title,
    requirement,
  }: {
    title: string;
    requirement: React.ReactNode | React.ReactNode[];
  }) {
    const [expand, setExpand] = useState(true);

    return (
      <div className="mb-5">
        <h3
          className="flex items-center justify-between hover:cursor-pointer mb-4"
          onClick={() => setExpand(!expand)}
        >
          <span>{title}</span>
          <span className="hover:cursor-pointer">
            {expand ? (
              <IconRemove fill="#3B4B5F" opacity={0.2} width={20} height={20} />
            ) : (
              <IconAdd fill="#3B4B5F" opacity={0.2} width={20} height={20} />
            )}
          </span>
        </h3>
        <div
          className={`transition-all duration-300 ease-in-out overflow-hidden  ${
            expand ? "max-h-[1000px] opacity-100 pb-5" : "max-h-0 opacity-0"
          }`}
        >
          {requirement}
        </div>
      </div>
    );
  }

  function ProgramAdvantages() {
    return (
      <div>
        <h2 className="my-24 mb-10">{programFullName}有什么优势？</h2>
        <table>
          {programData.advantages.length > 0 &&
            programData.advantages.map((item: string[], index: number) => (
              <tr
                key={"advantage-" + index}
                className=" border-gray-200 border-t"
              >
                <td className="py-5 pl-3 pr-6 ">{index + 1}</td>
                <td className="py-5 pl-6 pr-12 font-semibold">{item[0]}</td>
                <td className="py-5 px-6">{item[1]}</td>
              </tr>
            ))}
        </table>
      </div>
    );
  }

  function ProgramProcess() {
    return (
      <div className="my-40">
        <h2>{programFullName}申请流程</h2>
        <p className="text-secondary-light text-lg mt-2 mb-5">
          步骤为{programData.process.steps}步，周期为
          {programData.process.duration.min}-{programData.process.duration.max}
          {programData.process.duration.unit}
        </p>
        <ul className="grid grid-cols-4 gap-y-20 gap-x-4 mt-32 w-full  justify-between">
          {programData.process.details.length > 0 &&
            programData.process.details.map((item: string, index: number) => (
              <li
                key={"process-" + index}
                className="flex flex-col items-center gap-2"
              >
                <span>第{index + 1}步</span>
                <div className="relative">
                  {/* For 5th, 9th, 13th, etc. items - add left line with arrow */}
                  {index % 4 === 0 && index !== 0 && (
                    <div className="absolute top-[-4px] right-[10px] flex items-center">
                      <div className="h-[2px] bg-main/20 w-[50px] -mr-1"></div>
                      <IconArrowRight
                        className=""
                        fill="#3B4B5F"
                        opacity={0.2}
                        width={20}
                        height={20}
                      />
                    </div>
                  )}
                  {/* For all items except 4th, 8th, etc. and last item - add right line */}
                  {(index + 1) % 4 !== 0 &&
                    index !== programData.process.details.length - 1 && (
                      <div
                        className="absolute top-[5px] lef5-[3px] h-[2px] bg-main/20 "
                        style={{
                          width: `calc(((100vw - 13rem) / 4) + 1rem)`,
                        }}
                      ></div>
                    )}
                  <div className="h-3 w-3 bg-main/90 rounded-full relative z-10"></div>
                </div>
                <span className="font-bold text-center">{item}</span>
              </li>
            ))}
        </ul>
      </div>
    );
  }

  function MaltaNote() {
    return (
      <div>
        <p>马耳他政策变更</p>
        {programData.requirements.otherRequirement}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center pb-20 bg-gray-100 font-poster2">
      <HeroSection />
      {programData.id === 5 && <MaltaNote />}
      <div className="px-20">
        <CountryOverview />
        <Requirements />
        <ProgramAdvantages />
        <ProgramProcess />
      </div>
    </div>
  );
}
