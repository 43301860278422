import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/app/appSlice";
import layoutsReducer from "../features/app/layoutsSlice";
import productsReducer from "../features/products/productsSlice";
import cartReducer from "../features/cart/cartSlice";
import favouritesReducer from "../features/favourites/favouritesSlice";
import ordersReducer from "../features/orders/ordersSlice";
import userReducer from "../features/user/userSlice";

// import { thunk } from 'redux-thunk'; // Import redux-thunk

// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// const persistConfig: any = {
//     key: 'root',
//     storage,
//     blacklist: ['states, developments'] // Add this line to exclude the states slice from persistence
// };

const rootReducer = combineReducers({
  products: productsReducer,
  app: appReducer,
  layouts: layoutsReducer,
  cart: cartReducer,
  favourites: favouritesReducer,
  orders: ordersReducer,
  user: userReducer,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"], // ignore 'persist/PERSIST' action
      },
    }),
});

// let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store };
// export { store, persistor };
