import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { store } from "./app/store";
// import { persistor, store } from "./app/store";
import "./index.css";
import { App } from "./app/App";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";
// import { getCookieStateAnalytics } from "./features/app/UserRelatedSlice";
// import { PersistGate } from 'redux-persist/integration/react';

// ReactGA.initialize("");

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

const RootComponent = () => {
  // const analytics = useSelector(getCookieStateAnalytics);
  // useEffect(() => {
  //   if (analytics) {
  //     reportWebVitals(SendAnalytics);
  //   }
  // }, [analytics]);

  return <App />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <RootComponent />
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>
);
