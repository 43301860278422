import React from "react";
import { ImmigrationProgram } from "../types/program";
import {
  ReUkInvReq,
  ReUkProjReq,
  ReUkPerReq,
  ReUaeInvReq,
  ReUaePerReq,
  ReGreeceInvReq,
  ReGreeceDepReq,
  ReGreecePerReq,
  ReMaltaPolicyChangeNote,
  ReMaltaInvReq,
  ReMaltaPerReq,
  ReMaltaDepReq,
  PaTurkeyPerReq,
  PaTurkeyDepReq,
  PaTurkeyInvReq,
  PaMaltaInvReq,
  PaMaltaPerReq,
  PaMaltaDepReq,
  ReSpainInvReq,
  ReSpainPerReq,
  ReSpainOtherReqPR,
  ReSpainOtherReqCitizenship,
  RePortugalInvReq,
  RePortugalDepReq,
  RePortugalPerReq,
  ReSingaporeInvReq,
  ReSingaporePerReq,
  PaVanuatuPerReq,
  PaVanuatuInvReq,
  PaVanuatuDepReq,
  PaSaintKittsAndNevisInvReq,
  PaSaintKittsAndNevisPerReq,
  PaSaintKittsAndNevisDepReq,
  PaStLuciaReunionReq,
  PaAntiguaAndBarbudaPerReq,
  PaAntiguaAndBarbudaDepReq,
  PaAntiguaAndBarbudaInvReq,
  PaDominicaInvReq,
  PaDominicaPerReq,
  PaGrenadaInvReq,
  PaGrenadaPerReq,
  PaGrenadaDepReq,
  PaStLuciaInvReq,
  PaStLuciaPerReq,
  PaStLuciaDepReq,
} from "./programRequirements";
import {
  ukImgMap,
  ukImgFlag,
  ukImgBackground,
  uaeImgMap,
  uaeImgFlag,
  uaeImgBackground,
  greeceImgMap,
  greeceImgFlag,
  greeceImgBackground,
  turkeyImgMap,
  turkeyImgFlag,
  turkeyImgBackground,
  maltaImgMap,
  maltaImgFlag,
  maltaImgBackground,
  spainImgMap,
  spainImgFlag,
  spainImgBackground,
  portugalImgMap,
  portugalImgFlag,
  portugalImgBackground,
  singaporeImgMap,
  singaporeImgFlag,
  singaporeImgBackground,
  vanuatuImgMap,
  vanuatuImgFlag,
  vanuatuImgBackground,
  stKittsImgMap,
  stKittsImgFlag,
  stKittsImgBackground,
  stLuciaImgMap,
  stLuciaImgFlag,
  stLuciaImgBackground,
  dominicaImgMap,
  dominicaImgFlag,
  dominicaImgBackground,
  grenadaImgMap,
  grenadaImgFlag,
  grenadaImgBackground,
  antiguaImgMap,
  antiguaImgFlag,
  antiguaImgBackground,
} from "../assets/imgExport";

const residenceUk: ImmigrationProgram = {
  id: 1,
  image: {
    map: ukImgMap,
    flag: ukImgFlag,
    background: ukImgBackground,
  },
  country: "英国",
  countryEn: "uk",
  continent: "欧洲",
  description:
    "英国创新者签证要求投资50,000英镑，4-6个月获批，3年可申请永居。配偶可工作，子女享免费教育，全家享医疗福利，审批快速，适合移民英国。",
  immigrationType: {
    title: "创新者签证",
    type: "居留项目",
  },
  investmentAmount: {
    set: 50000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "英镑",
    symbol: "£",
  },
  countryOverview: {
    description: [
      "大不列颠及北爱尔兰联合王国(英语:The United Kingdom of Great Britain and Northern Ireland)，简称“英国”。",
      "英国是由大不列颠岛上的英格兰、威尔士和苏格兰以及爱尔兰岛东北部的北爱尔兰以及一系列附属岛屿共同组成的一个欧洲⻄部岛国。",
      "英国是一个高度发达的资本主义国家，欧洲四 大经济体之一，其国⺠拥有极高的生活水平和 良好的社会保障体系。英国是全球最大的金融 服务净出口国。 英国作为全球金融中心的地 位短时间内仍难撼动。",
    ],
    officialCurrency: "英镑",
    officialLanguage: "英语",
    visaFree: 190,
  },
  advantages: [
    [
      "先获得项目背书再申请签证",
      "商业项目均需要通过英国 内政部指定背书机构(Endorsing Body)进行背书支持，之 后递交签证申请降低被拒签⻛险",
    ],
    ["办理周期短", "4-6个月即可获批取得签证"],
    [
      "快速申请英国永居的路径",
      "主申请人3年后可申请永居，是最快能移⺠英国的方式之一",
    ],
    ["灵活安排", "申请人可全职、也可兼职工作"],
    ["全家一起申请", "配偶签证无需工作许可，可合法工作"],
    [
      "子女教育福利",
      "18周岁以下随行子女可免费就读于公立 学校，入读私立学校不受签证限制，选择范围与本地学生一致",
    ],
    [
      "全家医疗福利",
      "支付每人每年624英镑的NHS移⺠医疗附 加费后，可享受英国公立医疗体系，获得永居后无需支付NHS费用",
    ],
  ],
  requirements: {
    investmentRequirement: <ReUkInvReq />,
    projectRequirement: <ReUkProjReq />,
    personalRequirement: <ReUkPerReq />,
    dependentsRequirement: null,
    otherRequirement: null,
  },
  process: {
    steps: 9,
    duration: {
      min: 4,
      max: 6,
      unit: "个月",
    },
    details: [
      "评估签约",
      "材料收集、银行开户",
      "存入生活费、投资款、申请材料准备",
      "企业材料整合",
      "材料翻译、材料整合",
      "递交背书机构、申请背书",
      "面试、拿到背书信",
      "递交移⺠局审批",
      "获得签证",
    ],
  },
};

const residenceUae: ImmigrationProgram = {
  id: 2,
  image: {
    map: uaeImgMap,
    flag: uaeImgFlag,
    background: uaeImgBackground,
  },
  country: "阿联酋",
  countryEn: "uae",
  continent: "中东",
  description:
    "投资200万迪拉姆于迪拜房产，1-3个月获批，无居住限制，收益率高，全家享房产增值和税收优惠。",
  immigrationType: {
    title: "投资移民",
    type: "居留项目",
  },
  investmentAmount: {
    set: 2000000,
    optional: 0,
    above: false,
  },
  currency: {
    title: "迪拉姆",
    symbol: "迪拉姆",
  },
  countryOverview: {
    description: [
      "迪拜是阿拉伯联合酋⻓国人口最多的城市，也是中东地区的经济和金融中心。位于阿拉伯湾东南岸，为该国七个酋⻓国之一迪拜酋⻓国的首府。",
      "作为阿拉伯联合酋⻓国的经济中心，迪拜已经成为全球城市，同时也是世界主要客运及货运枢纽。迪拜采行⻄方商业模式，经济发展主要依赖观光业、航空业、房地产及金融服务业。",
    ],
    officialCurrency: "迪拉姆",
    officialLanguage: "阿拉伯语、英语",
    visaFree: 180,
  },
  advantages: [
    ["⻔槛低", "200万迪拉姆投资迪拜房产，相当于400万人⺠ 币投资⻔槛"],
    ["高收益", "6-8%回报率+房产增值，无投资税，无收入税"],
    [
      "灵活安排",
      "在阿联酋境外居留时间没有限制，在阿联酋工作、学习或⻓期居住，无需担保人",
    ],
    [
      "一步到位",
      "可为父母担保10年，可为子女担保至25岁，还可担保最多2名女佣和1名司机(为期2年)",
    ],
    ["资金安全", "可在阿联酋开设银行账户，期房投资前期资 金投入低"],
  ],
  requirements: {
    investmentRequirement: <ReUaeInvReq />,
    projectRequirement: null,
    personalRequirement: <ReUaePerReq />,
    dependentsRequirement: null,
    otherRequirement: null,
  },
  process: {
    steps: 8,
    duration: {
      min: 1,
      max: 3,
      unit: "个月",
    },
    details: [
      "评估签约",
      "远程/现场考察房产",
      "签署协定协议",
      "支付定金",
      "签署正式购房合同",
      "准备材料",
      "入境录指纹",
      "全家获得居⺠卡",
    ],
  },
};

const residenceGreece: ImmigrationProgram = {
  id: 3,
  image: {
    map: greeceImgMap,
    flag: greeceImgFlag,
    background: greeceImgBackground,
  },
  country: "希腊",
  countryEn: "greece",
  continent: "欧洲",
  description:
    "投资25万欧元购房，6-8个月获批，附带永久居留，畅游27个申根国，房产可出租或自住。",
  immigrationType: {
    title: "投资移⺠",
    type: "居留项目",
  },
  investmentAmount: {
    set: 250000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "欧元",
    symbol: "€",
  },
  countryOverview: {
    description: [
      "希腊共和国，简称希腊(Greece)，是地处欧洲东南⻆、巴尔干半岛的南端的共和制国家。全国由半岛南部的伯罗奔尼撒半岛和爱琴海中的3000余座岛屿构成。",
      "希腊为连接欧亚非的战略要地，本土从⻄北至正北部分别邻阿尔巴尼亚、北⻢其顿、保加利亚三国，东北与土耳其国境接壤。周围自东而⻄分别濒临爱琴海、地中海本域与伊奥尼亚海。",
      "希腊被视为⻄方文明的发源地，历史可上溯到古希腊。",
    ],
    officialCurrency: "欧元",
    officialLanguage: "希腊语",
    visaFree: 186,
  },
  advantages: [
    ["⻔槛低", "25万欧元投资⻔槛，欧洲入⻔级优质项目"],
    ["唯一“房产+绿卡”组合", "欧洲版“买房落户”，房产投资有保障，固定资产⻛险低"],
    ["教育可进可退", "欧美名校一键获取，回国名校轻松入读"],
    ["房产投资性", "世界级投资客众多，希腊房产价格稳步增 ⻓，出租+自住皆可"],
    ["维持成本低", "低税费，汇率+租金+房产增值"],
    ["100%获批，申请简单", "主申请人年龄要求宽泛，附属申请人100%可加入"],
    ["畅游欧洲，养老度假", "自由通行27个申根国"],
  ],
  requirements: {
    investmentRequirement: <ReGreeceInvReq />,
    projectRequirement: null,
    personalRequirement: <ReGreecePerReq />,
    dependentsRequirement: <ReGreeceDepReq />,
    otherRequirement: null,
  },
  process: {
    steps: 8,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "评估签约",
      "远程/现场考察房产",
      "签署协定协议",
      "支付定金",
      "签署正式购房合同",
      "准备材料",
      "入境录指纹",
      "全家获得永居卡",
    ],
  },
};

const passportTurkey: ImmigrationProgram = {
  id: 4,
  image: {
    map: turkeyImgMap,
    flag: turkeyImgFlag,
    background: turkeyImgBackground,
  },
  country: "土耳其",
  countryEn: "turkey",
  continent: "欧洲",
  description:
    "投资40万美元购房，6-8个月获护照，无居住要求，可申请美国E-2签证，3年后房产可出售。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 400000,
    optional: 0,
    above: false,
  },
  currency: {
    title: "美金",
    symbol: "$",
  },
  countryOverview: {
    description: [
      "土耳其共和国(土耳其语:Türkiye Cumhuriyeti;英语:The Republic of Turkey)地跨亚欧两大洲，政治、经济、文化 等领域均实行欧洲模式，欧盟候选国，1995 年与欧盟订立关税同盟，2005年开始入盟谈 判，目标2023年，建国100周年时加入欧盟。 宪法规定土耳其为⺠主、政教分离、实行法制 之国家，外交重心在⻄方，与美国保持传统战 略伙伴关系，同时强化与欧洲国家关系。",
      "土耳其是经济合作与发展组织(OECD)创始会 员国，二十国集团(G20)成员国，其拥有雄厚 工业基础，世界新兴经济体之一，全球发展最 快国家之一。",
    ],
    officialCurrency: "土耳其里拉",
    officialLanguage: "土耳其语",
    visaFree: 115,
  },
  advantages: [
    ["最高性价比", "投资金额最低的大国公⺠入籍项目之一"],
    ["无居住要求", "拿到护照后无任何居住要求"],
    ["申请要求简单", "无需证明学历、管理经验、资金来源以 及体检"],
    ["房价低且周期短", "土耳其目前房价低谷，投资好时机，3 年后即可卖出"],
    ["美国E-2签证缔约国", "拥有土耳其护照就是拥有申请美国 E-2签证的敲⻔砖"],
  ],
  requirements: {
    investmentRequirement: <PaTurkeyInvReq />,
    projectRequirement: null,
    personalRequirement: <PaTurkeyPerReq />,
    dependentsRequirement: <PaTurkeyDepReq />,
    otherRequirement: null,
  },
  process: {
    steps: 8,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "评估签约",
      "选择房源",
      "签署律师授权书",
      "支付所购房款",
      "准备移⺠材料",
      "申请居留许可",
      "获得居留证，申请公⺠身份",
      "获得护照",
    ],
  },
};

const residenceMalta: ImmigrationProgram = {
  id: 5,
  image: {
    map: maltaImgMap,
    flag: maltaImgFlag,
    background: maltaImgBackground,
  },
  country: "⻢耳他",
  countryEn: "malta",
  continent: "欧洲",
  description:
    "投资80,000欧元起，10-12个月获批，全家四代拿永居，无居住要求，享欧盟与申根福利。",
  immigrationType: {
    title: "永居移⺠",
    type: "居留项目",
  },
  investmentAmount: {
    set: 300000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "欧元",
    symbol: "€",
  },
  countryOverview: {
    description: [
      "⻢耳他共和国(⻢耳他语:Repubblika ta' Malta，英语:(Republic of Malta) 简称⻢耳他，位于南欧的共和制的一个国家，是一个位于地中海中心的岛国，有“地中海心脏”，“欧洲的乡村”之称“。官方语言为⻢耳他语和英语，首都瓦莱塔 (Valletta)。",
      "⻢耳他是一个高度发达的资本主义国家， 经济以服务业和金融业为主，旅游业是⻢耳他主要的外汇来源。⻢耳他社会保障体系较为完备，实行免费教育，免费医疗及退休保险制。",
    ],
    officialCurrency: "欧元",
    officialLanguage: "英语、⻢耳他语",
    visaFree: 187,
  },
  advantages: [
    [
      "拿永居，畅游申根",
      "投资获永居身份，全 球唯一的四位一体 国，即欧盟成员国、 申根国、欧元国、英 联邦国家",
    ],
    ["申请要求简单", "无管理经验、教育背 景、语言要求"],
    ["资金安全", "先批复，后投资"],
    ["一步到位", "全家四代拿欧盟永居"],
    ["移⺠不移居", "无居住要求"],
  ],
  requirements: {
    investmentRequirement: <ReMaltaInvReq />,
    projectRequirement: null,
    personalRequirement: <ReMaltaPerReq />,
    dependentsRequirement: <ReMaltaDepReq />,
    otherRequirement: [<ReMaltaPolicyChangeNote />],
  },
  process: {
    steps: 7,
    duration: {
      min: 10,
      max: 12,
      unit: "个月",
    },
    details: [
      "评估签约",
      "预背调，收集资料",
      "资料制作",
      "递交移⺠申请材料",
      "原则性批复",
      "登陆⻢耳他",
      "居留身份和永居卡",
    ],
  },
};

const passportMalta: ImmigrationProgram = {
  id: 6,
  image: {
    map: maltaImgMap,
    flag: maltaImgFlag,
    background: maltaImgBackground,
  },
  country: "⻢耳他",
  countryEn: "malta",
  continent: "欧洲",
  description:
    "投资60万欧元起，1.5-3.5年获批护照，全家四代拿欧盟身份，自由通行欧盟及申根国，享最高福利待遇。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 600000,
    optional: 750000,
    above: true,
  },
  currency: {
    title: "欧元",
    symbol: "€",
  },
  countryOverview: {
    description: [
      "⻢耳他共和国(⻢耳他语:Repubblika ta' Malta，英语:(Republic of Malta) 简称⻢耳他，位于南欧的共和制的一个国家，是一个位于地中海中心的岛国，有“地中海心脏”，“欧洲的乡村”之称“。官方语言为⻢耳他语和英语，首都瓦莱塔 (Valletta)。",
      "⻢耳他是一个高度发达的资本主义国家， 经济以服务业和金融业为主，旅游业是⻢耳他主要的外汇来源。⻢耳他社会保障体系较为完备，实行免费教育，免费医疗及退休保险制。",
    ],
    officialCurrency: "欧元",
    officialLanguage: "英语、⻢耳他语",
    visaFree: 187,
  },
  advantages: [
    ["顶级身份", "每年全球限量400组投资入籍名额"],
    [
      "欧洲自由居住",
      "可以在欧盟27个国家自由居住、生活和工作，并享受欧盟国家最高福利待遇",
    ],
    ["一步到位", "全家四代拿欧盟永居"],
  ],
  requirements: {
    investmentRequirement: <PaMaltaInvReq />,
    projectRequirement: null,
    personalRequirement: <PaMaltaPerReq />,
    dependentsRequirement: <PaMaltaDepReq />,
    otherRequirement: [<ReSpainOtherReqPR />, <ReSpainOtherReqCitizenship />],
  },
  process: {
    steps: 9,
    duration: {
      min: 1.5,
      max: 3.5,
      unit: "年",
    },
    details: [
      "评估签约",
      "预背调，收集材料",
      "资料制作",
      "递交移⺠申请材料",
      "原则性批复",
      "登陆⻢耳他",
      "居留身份和永居卡",
      "护照申请",
      "宣誓邀请阶段",
      "领⻢耳他护照",
    ],
  },
};

const residenceSpain: ImmigrationProgram = {
  id: 7,
  image: {
    map: spainImgMap,
    flag: spainImgFlag,
    background: spainImgBackground,
  },
  country: "⻄班牙",
  countryEn: "spain",
  continent: "欧洲",
  description:
    "投资50万欧元购房，6-8个月获批，可自由通行申根国，5年可申请永居，10年后可申请入籍。",
  immigrationType: {
    title: "投资移⺠",
    type: "居留项目",
  },
  investmentAmount: {
    set: 500000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "欧元",
    symbol: "€",
  },
  countryOverview: {
    description: [
      "⻄班牙王国(⻄班牙语:Reino de España)，通称⻄班牙(España)，国土面 积约占伊比利亚半岛的六分之五。其领土还包 括地中海中的巴利阿里群岛、大⻄洋的加那利 群岛、以及在非洲北部的休达、梅利利亚。首 都兼最大城市为⻢德里。",
      "其全球帝国的兴盛使得现今全球有5亿人口使 用⻄班牙语，令⻄班牙语成为世界上总使用人 数第三多、母语人数第二多的语言。",
      "⻄班牙是中等发达国家，拥有较高的社会福 利，人均GDP在三万美元左右。近些年受经 济危机影响在发达国家里失业率偏高。",
    ],
    officialCurrency: "欧元",
    officialLanguage: "西班牙语",
    visaFree: 190,
  },
  advantages: [
    ["申请简单", "无语言，学历的要求，无面试要求"],
    ["一步到位", "一人申请，全家获得身份(配偶、父母和子女可随同移⺠)"],
    ["申请⻔槛低", "不需要解释商业背景和资金来源"],
    [
      "畅游欧洲，养老度假",
      "可自由往返 27个欧洲申根国家，子女可以在⻄班牙享受免费教育",
    ],
  ],
  requirements: {
    investmentRequirement: <ReSpainInvReq />,
    projectRequirement: null,
    personalRequirement: <ReSpainPerReq />,
    dependentsRequirement: null,
    otherRequirement: null,
  },
  process: {
    steps: 5,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "评估签约",
      "选择购买适宜房产",
      "支付全款，完成房产注册后制作移⺠材料",
      "获得地契，将移⺠资料递交移⺠局",
      "20天获批1年投资居留签证",
    ],
  },
};

const residencePortugal: ImmigrationProgram = {
  id: 8,
  image: {
    map: portugalImgMap,
    flag: portugalImgFlag,
    background: portugalImgBackground,
  },
  country: "葡萄牙",
  countryEn: "portugal",
  continent: "欧洲",
  description:
    "投资50万欧元基金或房产，10-12个月获批，享免费医疗和教育，7天/年居住要求，5年后可申请永居。",
  immigrationType: {
    title: "投资移⺠",
    type: "居留项目",
  },
  investmentAmount: {
    set: 500000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "欧元",
    symbol: "€",
  },
  countryOverview: {
    description: [
      "葡萄牙共和国(葡萄牙语:República Portuguesa;英语:The Portuguese Republic)，是一个位于欧洲⻄南部的共和制 国家。东邻同处于伊比利亚半岛的⻄班牙，葡 萄牙的⻄部和南部是大⻄洋的海岸。",
      "除了欧洲大陆的领土以外，大⻄洋的亚速群岛和⻢德拉群岛也是葡萄牙领土。",
      "葡萄牙首都里斯本以⻄的罗卡⻆是欧洲大陆的 最⻄端，葡萄牙语是2.4亿人的共同母语，也 是世界第六大语言。",
      "葡萄牙气候温和，每年拥有3000多个小时的 日照时间和850 公里壮丽的大⻄洋海滩，它全 年都是理想的度假胜地。",
    ],
    officialCurrency: "欧元",
    officialLanguage: "葡萄牙语",
    visaFree: 188,
  },
  advantages: [
    ["高性价比的准欧盟身份", ""],
    ["", "自由通行欧洲27个申根国家"],
    ["", "无商业背景，无学术背景要求，无经济来源要求，无语言要求"],
    ["", "极低居住要求(14+14+14，平均每年7天)"],
    ["", "可以在葡萄牙自由工作和经商"],
    ["", "免费的公立医疗福利、公立教育福利"],
    [
      "",
      "新生儿”落地生“——父母一方持有合法身份满一年，出生在葡萄牙的新生儿直接获得葡萄牙身份",
    ],
    ["", "一人申请，三代移⺠，子女及双方父母均可同时申请"],
  ],
  requirements: {
    investmentRequirement: <RePortugalInvReq />,
    projectRequirement: null,
    personalRequirement: <RePortugalPerReq />,
    dependentsRequirement: <RePortugalDepReq />,
    otherRequirement: null,
  },
  process: {
    steps: 12,
    duration: {
      min: 10,
      max: 12,
      unit: "个月",
    },
    details: [
      "签约提供基本信息",
      "收到法律文件包，签署及认证，委托协议",
      "律师代开设税号及银行账户",
      "转投资款和其他费用",
      "房产过户(律师在葡代办)",
      "准备双认证相关文件",
      "律师在线提交移⺠文件",
      "SEF预审批通过",
      "预约指纹录入日期",
      "办理身份签证",
      "申请人赴葡打指纹",
      "SEF预审批通过",
    ],
  },
};

const residenceSingapore: ImmigrationProgram = {
  id: 9,
  image: {
    map: singaporeImgMap,
    flag: singaporeImgFlag,
    background: singaporeImgBackground,
  },
  country: "新加坡",
  countryEn: "singapore",
  continent: "亚洲",
  description:
    "投资1000万新币设立家族办公室，9-12个月获批，享税收优惠及资产配置自由，适合高净值家庭。",
  immigrationType: {
    title: "工作签证",
    type: "居留项目",
  },
  investmentAmount: {
    set: 10000000,
    optional: 0,
    above: false,
  },
  currency: {
    title: "新元",
    symbol: "S$",
  },
  countryOverview: {
    description: [
      "新加坡共和国(英语:Republic of Singapore)，是位于东南亚的城邦岛国、城 市国家。新加坡由新加坡岛等63个岛屿组 成，面积为728平方公里，因环境优美有花园 城市之美称。",
      "1970年代以来，新加坡经济迅速发展，被誉 为“亚洲四小⻰”之一，人均国内生产总值、人 类发展指数高居全球前列。目前，新加坡是全 球重要的炼油、贸易、物流中心，也是亚洲最 大的国际金融中心。2020年，新加坡人口逾 560万，是全球人口密度最高的国家之一，同 时，新加坡也是一个多元文化种族的社会，人 口组成以华人为多数，大多数的新加坡人都通 晓至少两种语言，分别是英语以及自己的母 语。",
    ],
    officialCurrency: "新加坡元",
    officialLanguage: "英语，华语，⻢来语，泰米尔语",
    visaFree: 194,
  },
  advantages: [
    ["⻔槛低", "无企业规模，投资背景或学历要求，无需创业和 积极运营公司。"],
    ["自由度", "自主选择海外资产配置，指定投资收益享受免税 政策。"],
    ["一劳永逸", "实现资产配置增值和全家移⺠身份配置。"],
    [
      "理想的家族办公室设立地",
      "政府扶持，透明法律政策，公 司法信托法完善，政治经济大环境稳定",
    ],
  ],
  requirements: {
    investmentRequirement: <ReSingaporeInvReq />,
    projectRequirement: null,
    personalRequirement: <ReSingaporePerReq />,
    dependentsRequirement: null,
    otherRequirement: null,
  },
  process: {
    steps: 10,
    duration: {
      min: 9,
      max: 12,
      unit: "个月",
    },
    details: [
      "评估签约",
      "KYC、背调",
      "申请材料准备",
      "提交新加坡金融管理局初审及面试",
      "注册新加坡公司以及开新加坡公户",
      "提交新加坡EP申请并获得EP",
      "提交新加坡金融局进行终审",
      "通过新加坡金融管理局终审",
      "执行投资计划",
      "获得签证",
    ],
  },
};

const passportVanuatu: ImmigrationProgram = {
  id: 10,
  image: {
    map: vanuatuImgMap,
    flag: vanuatuImgFlag,
    background: vanuatuImgBackground,
  },
  country: "瓦努阿图",
  countryEn: "vanuatu",
  continent: "大洋洲",
  description:
    "捐款8万美元，最快1个月获批护照，无居住要求，英联邦护照适合资产规划及国际通行。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 80000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "美元",
    symbol: "$",
  },
  countryOverview: {
    description: [
      "瓦努阿图共和国 位于南太平洋⻄部，属美拉 尼⻄亚群岛，由83个岛屿(其中68个岛屿有 人居住)组成。最大的岛屿是桑托岛(面积 3974平方公里)，属热带海洋性气候，年均 降水量1600毫米。首都维拉港平均气温 25.3°C。",
    ],
    officialCurrency: "瓦图",
    officialLanguage: "英语、法语和比斯拉⻢语，通用 比斯拉⻢语",
    visaFree: 91,
  },
  advantages: [
    [
      "周期短",
      "市场上办理周期最快的护照，从签约到领取护 照，平均45天，最快成功案例只需一个月。",
    ],
    ["合法入籍", "大洋洲仅有的合法入籍的护照项目。"],
    [
      "含金量高的英联邦护照",
      "适用于全球资产规划、海外架 构搭建、全球自由通行、享受国际教育等需求的客户， 是一本含金量极高的英联邦护照。",
    ],
    ["一步到位", "一人申请，三代同行"],
    ["移⺠不移居", "无登陆及居住要求"],
  ],
  requirements: {
    investmentRequirement: <PaVanuatuInvReq />,
    projectRequirement: null,
    personalRequirement: <PaVanuatuPerReq />,
    dependentsRequirement: <PaVanuatuDepReq />,
    otherRequirement: [<PaStLuciaReunionReq />],
  },
  process: {
    steps: 6,
    duration: {
      min: 1,
      max: 2,
      unit: "个月",
    },
    details: [
      "评估签约",
      "收集材料、制作",
      "背景调查",
      "递交材料",
      "获得原则性批复函",
      "获得护照并宣誓",
    ],
  },
};

const passportSaintKitts: ImmigrationProgram = {
  id: 11,
  image: {
    map: stKittsImgMap,
    flag: stKittsImgFlag,
    background: stKittsImgBackground,
  },
  country: "圣基茨",
  countryEn: "st-kitts-and-nevis",
  continent: "美洲",
  description:
    "捐款25万美元起，6-8个月获批，无居住要求，免签156国，享税务优势及全球资产规划便利。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 250000,
    optional: 0,
    above: false,
  },
  currency: {
    title: "美元",
    symbol: "$",
  },
  countryOverview: {
    description: [
      "圣基茨和尼维斯联邦(The Federation of Saint Kitts and Nevis)，简称“圣基茨和尼维 斯”，位于中美洲加勒比海地区小安的列斯群 岛北部背⻛群岛，是一个由圣基茨岛和尼维斯 岛组成的联邦制岛国。于1983年9月19日独 立，现为英联邦成员国之一。圣基茨和尼维斯 护照项目开设于1984年，是世界上最早开设 护照项目的国家。政策成熟稳定，申请条件简 单。",
    ],
    officialCurrency: "东加勒比元和美元",
    officialLanguage: "英语",
    visaFree: 156,
  },
  advantages: [
    ["美洲护照", "世界上最古老合法一步到位拿护照的移⺠项 目"],
    ["要求简单", "无语言、年龄、经商经验、资产额度、资金 来源等要求"],
    ["无移⺠监", "从申请到拿护照期间无居住、无登陆要求"],
    ["税务优势", "无个人所得税、海外收入征税、资本利得税、遗产税、赠与税"],
    ["法律依据", "1984年列入国家宪法，移⺠局官网有据可查"],
    ["历史悠久", "1984开始实施，早于加拿大、澳洲等大国"],
  ],
  requirements: {
    investmentRequirement: <PaSaintKittsAndNevisInvReq />,
    projectRequirement: null,
    personalRequirement: <PaSaintKittsAndNevisPerReq />,
    dependentsRequirement: <PaSaintKittsAndNevisDepReq />,
    otherRequirement: null,
  },
  process: {
    steps: 7,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "评估签约",
      "收集资料",
      "资料制作",
      "资料递交",
      "获得原则性批复函",
      "获得原则批复",
      "获得护照",
    ],
  },
};

const passportAntigua: ImmigrationProgram = {
  id: 12,
  image: {
    map: antiguaImgMap,
    flag: antiguaImgFlag,
    background: antiguaImgBackground,
  },
  country: "安提瓜和巴布达",
  countryEn: "antigua-and-barbuda",
  continent: "美洲",
  description:
    "捐款23万美元起，6-8个月获批，无需居住，免签165国，三代同移，适合税务优化。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 230000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "美元",
    symbol: "$",
  },
  countryOverview: {
    description: [
      "安提瓜和巴布达(Antigua and Barbuda)， 位于加勒比海小安的列斯群岛的北部。为英联 邦成员国。安提瓜和巴布达岛属于热带气候， 年平均气温27°C。年均降水量约1020毫米。 安提瓜和巴布达国⺠收入主要来源于旅游、建 筑业、离岸金融业、制造业和农业。",
    ],
    officialCurrency: "东加勒比元和美元",
    officialLanguage: "英语",
    visaFree: 165,
  },
  advantages: [
    ["美洲护照", "一步到位拿护照，全家移⺠首选"],
    ["要求简单", "无语言、年龄、经商经验、资产额度、资金来源等要求"],
    ["无移⺠监", "从申请到拿护照期间无居住、五年内登陆安提瓜1次宣誓"],
    ["税务优势", "无个人所得税、海外收入征税、资本利得税、遗产税、赠与税"],
    ["资料简单", "只需提供个人及家庭、资信证明、工作证明 文件等"],
  ],
  requirements: {
    investmentRequirement: <PaAntiguaAndBarbudaInvReq />,
    projectRequirement: null,
    personalRequirement: <PaAntiguaAndBarbudaPerReq />,
    dependentsRequirement: <PaAntiguaAndBarbudaDepReq />,
    otherRequirement: null,
  },
  process: {
    steps: 6,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "评估签约",
      "文件制作",
      "递交申请",
      "获得原则性批复函",
      "支付投资款或者捐款",
      "获得护照",
    ],
  },
};

const passportDominica: ImmigrationProgram = {
  id: 13,
  image: {
    map: dominicaImgMap,
    flag: dominicaImgFlag,
    background: dominicaImgBackground,
  },
  country: "多米尼克",
  countryEn: "dominica",
  continent: "美洲",
  description:
    "捐款20万美元起，6-8个月获批，无居住要求，免签140国，全家四代可同时移民。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 200000,
    optional: 0,
    above: true,
  },
  currency: {
    title: "美元",
    symbol: "$",
  },
  countryOverview: {
    description: [
      "多米尼克国(The Commonwealth of Dominica)是位于东加勒比海向⻛群岛东北 部的一个岛国。东临大⻄洋，⻄濒加勒比海， 南与⻢提尼克岛隔⻢提尼克海峡、北同瓜德罗 普隔多米尼克海峡相望。国土面积751平方公 里，年均气温25至32度，热带海洋气候。现 为英联邦成员国。 多米尼克投资公⺠计划始 于1993年。",
    ],
    officialCurrency: "东加勒比元和美元",
    officialLanguage: "英语",
    visaFree: 140,
  },
  advantages: [
    ["美洲护照", "一步到位拿护照，全家四代移⺠"],
    ["要求简单", "无语言、年龄、经商经验、资产额度、资金来源等要求"],
    ["无移⺠监", "从申请到拿护照期间无居住、无登陆要求"],
    ["税务优势", "无个人所得税、海外收入征税、资本利得 税、遗产税、赠与税"],
    ["政策稳定", "移⺠法案写在国家最高宪法中，稳定安全"],
    ["资料简单", "只需提供个人及家庭、资信证明、工作证明 文件等"],
  ],
  requirements: {
    investmentRequirement: <PaDominicaInvReq />,
    projectRequirement: null,
    personalRequirement: <PaDominicaPerReq />,
    dependentsRequirement: null,
    otherRequirement: null,
  },
  process: {
    steps: 4,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "评估签约",
      "材料递交",
      "背景调查，原则性批复",
      "获得护照及公⺠纸",
    ],
  },
};

const passportGrenada: ImmigrationProgram = {
  id: 14,
  image: {
    map: grenadaImgMap,
    flag: grenadaImgFlag,
    background: grenadaImgBackground,
  },
  country: "格林纳达",
  countryEn: "grenada",
  continent: "美洲",
  description:
    "捐款23.5万美元起，6-8个月获批，可申请美国E-2签证，与中国互免签证，三代同移。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 235000,
    optional: 0,
    above: false,
  },
  currency: {
    title: "美元",
    symbol: "$",
  },
  countryOverview: {
    description: [
      "格林纳达(英文:Grenada)位于东加勒比海 向⻛群岛的最南端，南距委内瑞拉海岸约160 公里。属热带海洋性气候，年平均气温 26°C。农业和旅游业是经济的基础，主要农 产品肉豆蔻产量约占世界总产量的三分之一， 仅次于印度尼⻄亚，居世界第二位。现为英联 邦成员国。格林纳达于2013年新的投资移⺠ 法案颁布，格林纳达政府为推行新经济政策， 成立国家发展基金为了促进该计划的发展。",
    ],
    officialCurrency: "美元和东加勒比元",
    officialLanguage: "英语",
    visaFree: 140,
  },
  advantages: [
    ["美洲护照", "一步到位拿护照，全家四代移⺠"],
    ["要求简单", "无语言、年龄、经商经验、资产额度、资金来源等要求"],
    ["无移⺠监", "从申请到拿护照期间无居住、无登陆要求"],
    ["税务优势", "无海外收入征税、资本利得、遗产、赠与税"],
    ["政策稳定", "移⺠法案写在国家最高宪法中，稳定安全"],
    ["资料简单", "只需个人及家庭、资信证明、工作证明等"],
    [
      "中国免签",
      "加勒比护照项目中唯一与中国互相免签的国 家，并同时可获得该国永居",
    ],
  ],
  requirements: {
    investmentRequirement: <PaGrenadaInvReq />,
    projectRequirement: null,
    personalRequirement: <PaGrenadaPerReq />,
    dependentsRequirement: <PaGrenadaDepReq />,
    otherRequirement: null,
  },
  process: {
    steps: 7,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "评估签约及预背调",
      "银行清算",
      "原则性批复",
      "完成捐款",
      "确认捐款到账",
      "完成宣誓",
      "获得护照及公⺠纸",
    ],
  },
};

const passportSaintLucia: ImmigrationProgram = {
  id: 15,
  image: {
    map: stLuciaImgMap,
    flag: stLuciaImgFlag,
    background: stLuciaImgBackground,
  },
  country: "圣卢⻄亚",
  countryEn: "st-lucia",
  continent: "美洲",
  description:
    "捐款24万美元，6-8个月获批，无居住要求，免签150国，适合资产规划及家庭移民。",
  immigrationType: {
    title: "护照",
    type: "护照项目",
  },
  investmentAmount: {
    set: 240000,
    optional: 0,
    above: false,
  },
  currency: {
    title: "美元",
    symbol: "$",
  },
  countryOverview: {
    description: [
      "圣卢⻄亚(英语:Saint Lucia)，位于东加勒 比海向⻛群岛中部，北邻⻢提尼克岛，⻄南邻 圣文森特岛，是小安地列斯群岛的一部分。圣 卢⻄亚是座火山岛。现为英联邦成员国。 圣 卢⻄亚投资入籍计划受2015年《投资入籍 法》第14条管辖，该法案第33章节建立的一 项特殊基金正式针对全球投资入籍申请人的投 资移⺠项目。",
    ],
    officialCurrency: "东加勒比元和美元",
    officialLanguage: "英语",
    visaFree: 150,
  },
  advantages: [
    ["美洲护照", "一步到位拿护照，全家四代移⺠"],
    ["要求简单", "无语言、年龄、经商经验、资产额度、资金来源等要求"],
    ["无移⺠监", "从申请到拿护照期间无居住、无登陆要求"],
    ["税务优势", "无个人所得税、海外收入征税、资本利得 税、遗产税、赠与税"],
    ["政策稳定", "移⺠法案写在国家最高宪法中，稳定安全"],
    ["资料简单", "只需提供个人及家庭、资信证明、工作证明 文件等"],
  ],
  requirements: {
    investmentRequirement: <PaStLuciaInvReq />,
    projectRequirement: null,
    personalRequirement: <PaStLuciaPerReq />,
    dependentsRequirement: <PaStLuciaDepReq />,
    otherRequirement: null,
  },
  process: {
    steps: 5,
    duration: {
      min: 6,
      max: 8,
      unit: "个月",
    },
    details: [
      "签约-材料收集",
      "递交全套资料",
      "获得护照批复",
      "完成投资",
      "获得护照及公⺠纸",
    ],
  },
};

export const programResidenceData = {
  uk: residenceUk,
  uae: residenceUae,
  greece: residenceGreece,
  malta: residenceMalta,
  spain: residenceSpain,
  portugal: residencePortugal,
  singapore: residenceSingapore,
};

export const programPassportData = {
  malta: passportMalta,
  turkey: passportTurkey,
  vanuatu: passportVanuatu,
  "st-kitts-and-nevis": passportSaintKitts,
  "antigua-and-barbuda": passportAntigua,
  dominica: passportDominica,
  grenada: passportGrenada,
  "st-lucia": passportSaintLucia,
};
