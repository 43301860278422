import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import styles from "./HomePage.module.scss";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { getLayoutHeaderHeight } from "../../features/app/layoutsSlice";
import { getLang } from "../../features/app/appSlice";
import { getIsMobile, getIsRealMobile } from "../../features/app/appSlice";
import SubscribeSection from "../../components/Subscribe/Subscribe";
import Help from "../../items/Help/Help";
import {
  IconCorporate,
  IconGlobe,
  IconNote,
  IconRight,
  IconTeam,
} from "../../assets/svgExport";

const oiscLogo = require("../../assets/media/logo/oisc-logo-blue.png");
const ilpaLogo = require("../../assets/media/logo/ilpa-logo-blue.png");
const ciolLogo = require("../../assets/media/logo/ciol-logo-blue.png");
const background = require("../../assets/media/background/london.jpg");
export default function HomePage(props: any) {
  // SELECTORS //
  const lang = useSelector(getLang);
  const isRealMobile = useSelector(getIsRealMobile);
  const headerHeight = useSelector(getLayoutHeaderHeight);

  // TOOLS //
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const scrollToContact = location.state && location.state.scrollToContact;

  // STATES //

  // REFS //

  // CALLBACKS AND FUNCTIONS //

  // COMPONENTS //

  // EFFECTS //

  function HeroSection() {
    return (
      <div
        className="relative flex flex-col p-10 justify-center items-center text-white"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="relative z-10">
          <h1 className="text-inherit flex flex-col gap-4">
            <span>
              Global Vision
              <br />
              Local Expertise
            </span>
            <span className="text-3xl font-main-sans">国际视野 本地专识</span>
          </h1>
          <div className="h-[3px] bg-gray-200 w-80 my-8"></div>
          <p className="w-[55%] leading-relaxed">
            我们秉承“洞悉时局变迁，专业规划配置，稳定增值传延”的理念，专注于全球资产配置和国际身份规划，为您与家族提供一站式财富管理与传承服务。
          </p>

          <HashLink
            className="inline-block mt-5 bg-transparent border border-white px-10 py-4 hover:border-white/50"
            to="/#about-section"
          >
            了解更多
          </HashLink>
        </div>
        <div className="absolute top-0 h-full w-full">
          <img
            className="h-full w-full object-cover"
            src={background}
            alt={"london"}
          />
          <div className="absolute inset-0  bg-gradient-to-r from-black/70 via-black/50 to-transparent" />
        </div>
      </div>
    );
  }

  function OurExpertiseSection() {
    const data = [
      {
        type: "身份规划",
        service: "居留项目",
        description:
          "涵盖英国创新者签证、阿联酋、希腊、马耳他、西班牙、葡萄牙、新加坡等七大选择，助您实现身份升级、跨区生活与全球化发展。",
        hashLink: "/program#residence",
      },
      {
        type: "身份规划",
        service: "护照项目",
        description:
          "囊括土耳其、马耳他、瓦努阿图、圣基茨、安提瓜和巴布达、多米尼克、格林纳达、圣卢西亚等八种护照，快速获取更高国际通行度与广阔商业机遇。",
        hashLink: "/program#passport",
      },
      {
        type: "财富规划",
        service: "税务规划",
        description:
          "根据客户的全球资产布局与身份需求，制定合理的税务架构，充分利用国际间的税收协定和优惠政策，帮助企业与个人合法合规地降低税负，实现全球财富的优化配置。",
        hashLink: "/wealth#tax",
      },
      {
        type: "财富规划",
        service: "公司服务",
        description:
          "提供全球范围的公司注册、财务与合规咨询，协助客户搭建境内外商业架构，提升跨境经营效率与管理水平，为国际化业务发展奠定稳固基础。",
        hashLink: "/wealth#company",
      },
      {
        type: "传承规划",
        service: "家族信托",
        description:
          "协助高净值家庭搭建家族信托架构，有效保护与传承财富，兼顾资产安全与保值增值，同时避免潜在家庭、法律风险。",
        hashLink: "/legacy#trust",
      },
      {
        type: "传承规划",
        service: "教育规划",
        description:
          "针对下一代教育需求，提供全球教育资源对接、名校申请辅导、留学全程支持等，为后代教育与未来发展做好长远布局。",
        hashLink: "/legacy#education",
      },
      {
        type: "传承规划",
        service: "慈善公益",
        description:
          "结合客户家族价值观与社会责任需求，规划并落实慈善捐赠、公益项目投办等，实现财富回馈社会的长期影响与家族精神传承。",
        hashLink: "/legacy#charity",
      },
    ];

    return (
      <div id="about-section" className="my-32 mx-20">
        <h2 className="mb-10">我们的专业领域</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-16 gap-y-24">
          {data.length > 0 &&
            data.map((item, index) => (
              <div key={"our-expertise-" + index}>
                <p className="bg-c-gray/100 px-4 py-1 text-main/60 rounded-lg inline-block">
                  {item.type}
                </p>
                <h3 className="mt-3 mb-5">{item.service}</h3>
                <p className="text-secondary-dark/80 leading-relaxed">
                  {item.description}
                </p>
                <HashLink
                  to={item.hashLink}
                  className={"flex gap-1 mt-6 items-center text-gray-300"}
                >
                  <span>了解更多</span>
                  <IconRight width={16} height={16} fill="rgb(209 213 219)" />
                </HashLink>
              </div>
            ))}
        </div>
      </div>
    );
  }

  function AboutSection() {
    return (
      <div className="bg-c-gray py-32 px-20 flex gap-20 justify-between">
        <div>
          <h2>新愿景家族办公室</h2>
          <div className="flex gap-2 mt-4">
            <img className="h-20" src={oiscLogo} alt="OISC logo" />
            <img className="h-20" src={ilpaLogo} alt="ILPA logo" />
            <img className="h-20" src={ciolLogo} alt="CIOL logo" />
          </div>
        </div>
        <div className="w-[50%] flex flex-col gap-10 leading-relaxed text-secondary-dark/90">
          <p>
            <span className="font-bold">
              新愿景家族办公室（New Vision Family Office，简称NVFO）
            </span>
            ，秉承“洞悉时局变迁，专业规划配置，稳定增值传延”的理念，为诸多中国的超高净值家族提供一站式全球资产配置、身份和教育规划、财富管理传承和私人银行等服务。
          </p>
          <p>
            新愿景家族办公室是
            <span className="font-bold">
              英国内政部移民事务署（OISC）特许法律机构
            </span>
            ，并受其监管，为客户提供最专业的法律咨询和合规服务，迄今为止已为数百组客户与家庭成功完成移民身份规划，享受全球公民的便利与福利。
          </p>
          <p>
            我们的创始团队均毕业于世界知名学府
            <span className="font-bold">英国剑桥大学</span>
            ，用多年研学所成与行业专业积淀，以您和家族的需求为基石，打造一个专于您且适配于您的，高度前瞻性、私隐性、专业性和可持续发展性的家族办公室综合服务。
          </p>
        </div>
      </div>
    );
  }

  function OurAdvantageSection() {
    const data = [
      {
        icon: <IconCorporate fill="#b1b7bf" width="25" height="25" />,
        title: "一站式家族办公室",
        description:
          "新愿景家族办公室总部位于伦敦，拥有强大海内外权威专家团队，核心成员来自国际金融投资、财富管理、身份规划、税务规划、法律咨询、教育专家等不同领域，多年行业沉淀，提供家族办公室全方位、一站式综合解决方案。",
      },
      {
        icon: <IconGlobe fill="#b1b7bf" width="25" height="25" />,
        title: "国际视野下的资源整合实力",
        description:
          "新愿景家族办公室立足于欧洲，不断整合全球优质合作伙伴，充分调动海内外优势资源，满足客户多元化需求，为超高净值家族提供全球视野下未来生活的更多可能。",
      },
      {
        icon: <IconTeam fill="#b1b7bf" width="25" height="25" />,
        title: "专注服务中国超高净值家族",
        description:
          "新愿景家族办公室多年来致力于服务中国超高净值家族，一直保持着高度的家族满意度，精准提供同时满足家族多项需求的最优规划。",
      },
      {
        icon: <IconNote fill="#b1b7bf" width="25" height="25" />,
        title: "专家一对一定制服务",
        description:
          "新愿景家族办公室专家提供一对一定制服务，保护家族隐私，专业并高效完成家族委托。",
      },
    ];
    return (
      <div className="my-32 mx-20 mb-40">
        <h2>我们的优势</h2>
        <div className="grid grid-cols-2 gap-20 mt-10">
          {data.length > 0 &&
            data.map((item, index) => (
              <div key={"our-advantage-" + index}>
                <div className="mt-3 mb-6 flex items-center gap-3">
                  {item.icon}
                  <h3>{item.title}</h3>
                </div>
                <p className="text-secondary-dark/80 leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <HeroSection />
      <OurExpertiseSection />
      <AboutSection />
      <OurAdvantageSection />
      {/* <SubscribeSection /> */}
    </div>
  );
}
