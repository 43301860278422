// change or modify:
export const ukImgBackground = require("./media/background/country/uk.jpg");

// ok
export const uaeImgBackground = require("./media/background/country/uae.jpg");
export const greeceImgBackground = require("./media/background/country/greece.jpg");
export const turkeyImgBackground = require("./media/background/country/turkey.jpg");
export const maltaImgBackground = require("./media/background/country/malta.jpg");
export const spainImgBackground = require("./media/background/country/spain.jpg");
export const portugalImgBackground = require("./media/background/country/portugal.jpg");
export const singaporeImgBackground = require("./media/background/country/singapore.jpg");
export const vanuatuImgBackground = require("./media/background/country/vanuatu.jpg");
export const stKittsImgBackground = require("./media/background/country/st-kitts-and-nevis.jpg");
export const stLuciaImgBackground = require("./media/background/country/st-lucia.jpg");
export const dominicaImgBackground = require("./media/background/country/dominica.jpg");
export const grenadaImgBackground = require("./media/background/country/grenada.jpg");
export const antiguaImgBackground = require("./media/background/country/antigua-and-barbuda.jpg");

export const ukImgFlag = require("./media/background/flags/Flag_of_the_United_Kingdom.jpeg");
export const uaeImgFlag = require("./media/background/flags/Flag_of_the_United_Arab_Emirates.jpeg");
export const greeceImgFlag = require("./media/background/flags/Flag_of_Greece.jpeg");
export const turkeyImgFlag = require("./media/background/flags/Flag_of_Turkey.jpeg");
export const maltaImgFlag = require("./media/background/flags/Flag_of_Malta.jpeg");
export const spainImgFlag = require("./media/background/flags/Flag_of_Spain.jpeg");
export const portugalImgFlag = require("./media/background/flags/Flag_of_Portugal.jpeg");
export const singaporeImgFlag = require("./media/background/flags/Flag_of_Singapore.jpeg");
export const vanuatuImgFlag = require("./media/background/flags/Flag_of_Vanuatu.jpeg");
export const stKittsImgFlag = require("./media/background/flags/Flag_of_Saint_Kitts_and_Nevis.jpeg");
export const stLuciaImgFlag = require("./media/background/flags/Flag_of_Saint_Lucia.jpeg");
export const dominicaImgFlag = require("./media/background/flags/Flag_of_Dominica.jpeg");
export const grenadaImgFlag = require("./media/background/flags/Flag_of_Grenada.jpeg");
export const antiguaImgFlag = require("./media/background/flags/Flag_of_Antigua_and_Barbuda.jpeg");

export const ukImgMap = require("./media/background/maps/uk-map.jpeg");
export const uaeImgMap = require("./media/background/maps/uae-map.jpeg");
export const greeceImgMap = require("./media/background/maps/greece-map.jpeg");
export const turkeyImgMap = require("./media/background/maps/turkey-map.jpeg");
export const maltaImgMap = require("./media/background/maps/malta-map.jpeg");
export const spainImgMap = require("./media/background/maps/spain-map.jpeg");
export const portugalImgMap = require("./media/background/maps/portugal-map.jpeg");
export const singaporeImgMap = require("./media/background/maps/singapore-map.jpeg");
export const vanuatuImgMap = require("./media/background/maps/vanuatu-map.jpeg");
export const stKittsImgMap = require("./media/background/maps/st-kitts-and-nevis-map.jpeg");
export const stLuciaImgMap = require("./media/background/maps/st-lucia-map.jpeg");
export const dominicaImgMap = require("./media/background/maps/dominica-map.jpeg");
export const grenadaImgMap = require("./media/background/maps/grenada-map.jpeg");
export const antiguaImgMap = require("./media/background/maps/antigua-and-barbuda-map.jpeg");

export const iconFlagUK = require("./media/background/flags/Flag_of_the_United_Kingdom.png");
export const iconFlagUAE = require("./media/background/flags/Flag_of_the_United_Arab_Emirates.png");
export const iconFlagGreece = require("./media/background/flags/Flag_of_Greece.png");
export const iconFlagTurkey = require("./media/background/flags/Flag_of_Turkey.png");
export const iconFlagMalta = require("./media/background/flags/Flag_of_Malta.png");
export const iconFlagSpain = require("./media/background/flags/Flag_of_Spain.png");
export const iconFlagPortugal = require("./media/background/flags/Flag_of_Portugal.png");
export const iconFlagSingapore = require("./media/background/flags/Flag_of_Singapore.png");
export const iconFlagVanuatu = require("./media/background/flags/Flag_of_Vanuatu.png");
export const iconFlagStKitts = require("./media/background/flags/Flag_of_Saint_Kitts_and_Nevis.png");
export const iconFlagStLucia = require("./media/background/flags/Flag_of_Saint_Lucia.png");
export const iconFlagDominica = require("./media/background/flags/Flag_of_Dominica.png");
export const iconFlagGrenada = require("./media/background/flags/Flag_of_Grenada.png");
export const iconFlagAntigua = require("./media/background/flags/Flag_of_Antigua_and_Barbuda.png");

export const nvfoLogoBlue = require("./media/logo/nvfo-logo-blue.png");
export const nvfoLogoWhite = require("./media/logo/nvfo-logo-white.png");
// export const nvfoWechatFollowQR = require("./media/logo/wechat-follow-qrcode.png");
export const nvfoWechatChatQR = require("./media/logo/wechat-chat-qrcode.png");

export const teamBackground1 = require("./media/background/team1.jpg");
export const teamBackground2 = require("./media/background/team2.jpg");
export const teamBackground3 = require("./media/background/team3.jpg");
export const backgroundProgram = require("./media/background/globe.jpeg");
export const backgroundWealth = require("./media/background/team4.jpg");
export const backgroundLegacy = require("./media/background/team5.jpg");
export const schoolsBackground = require("./media/background/schools.png");
